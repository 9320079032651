import React, { Component } from "react";

const MEDIENDATENBANKEN = "Mediendatenbank";

const BREADCRUMB = content =>
  `<li itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem">${content}</li>`;

const BREADCRUMB_ITEM = name =>
  BREADCRUMB(`<span itemprop="name">${name}</span>`);

const BREADCRUMB_LINK_ITEM = (url, name) =>
  BREADCRUMB(
    `<a href="${url}" title="${name}" itemprop="item"><span itemprop="name">${name}</span></a>`,
  );

const BASE_URL = `${window.location.protocol}//${window.location.host}${window.location.pathname}#/`;

function buildBreadcrumb(items) {
  return items.reduce((content, { name, href }) => {
    return `${content}${
      href != null ? BREADCRUMB_LINK_ITEM(href, name) : BREADCRUMB_ITEM(name)
    }`;
  }, "");
}

class ModCrumb extends Component {
  componentDidMount() {
    // Store current breadcrumb and title …
    // … and update with new one
    const breadcrumb = document.querySelector(".breadcrumb");
    const title = document.querySelector("title");
    if (title) {
      this.titleInnerHTML = title.innerHTML;
    }
    if (breadcrumb) {
      this.breadcrumb = breadcrumb;
      this.breadcrumbItems = [];
      const listItems = breadcrumb.querySelectorAll("li");

      for (let listItem of listItems) {
        const content = listItem.childNodes[0];
        let name, href;

        if (content.tagName === "A") {
          href = content.href;
          name = content.childNodes[0].innerHTML;
        } else {
          name = content.innerHTML;
        }

        this.breadcrumbItems.push({ name, href });
      }

      this.updateTitle();
      this.updateBreadcrumbs();
    }
  }

  componentDidUpdate() {
    // Update breadcrumb and title
    this.updateTitle();
    this.updateBreadcrumbs();
  }

  componentWillUnmount() {
    // Restore breadcrumb and title
    const breadcrumb = document.querySelector(".breadcrumb");
    const title = document.querySelector("title");
    if (title && breadcrumb) {
      title.innerHTML = this.titleInnerHTML;
    }
    if (breadcrumb) {
      breadcrumb.innerHTML = buildBreadcrumb(this.breadcrumbItems);
    }
  }

  updateTitle() {
    const { label } = this.props;
    const title = document.querySelector("title");
    if (title) {
      title.innerHTML = this.titleInnerHTML.replace(MEDIENDATENBANKEN, label);
    }
  }

  updateBreadcrumbs() {
    const { label } = this.props;
    const breadcrumb = document.querySelector(".breadcrumb");
    if (breadcrumb) {
      const breadcrumbItems = [
        ...this.breadcrumbItems.slice(0, -1),
        {
          name: MEDIENDATENBANKEN,
          href: BASE_URL,
        },
        {
          name: label,
        },
      ];

      breadcrumb.innerHTML = buildBreadcrumb(breadcrumbItems);
    }
  }

  render() {
    return null;
  }
}

export default ModCrumb;
