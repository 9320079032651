import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import UnderlinedIconButton from "./UnderlinedIconButton";
import { inject } from "mobx-react";
import { CREATE_LINK_TO_MDB } from "../constants";
const useStyles = makeStyles(theme => ({
  a: {
    textDecoration: "none",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: theme.palette.primary.dark,
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(5),
  },
  claim: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: "18px",
    verticalAlign: "bottom",
    [theme.breakpoints.down("sm")]: {
      width: "17px",
      verticalAlign: "sub",
    },
  },
  span: {
    whiteSpace: "nowrap",
  },
}));

const ShareholderMVMMeta = props => {
  const { name, item, MVMcoproration, dataStore } = props;
  const classes = useStyles(props);
  const mvmData = dataStore.mkmByCoporationSquuid[item.squuid];
  if (!mvmData) return null;
  const mvmValue = dataStore.mkmValueForCoporation(item.squuid);
  const valueRounded = Math.round(mvmValue * 100) / 100;
  const coporation = mvmData["Konzern"];
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.claim}>
          Mediennutzungsbezogene Marktanteile von {name} werden im Rahmen des
          Medienvielfaltsmonitors ausgewiesen.
        </Typography>
        <Typography variant="body1">
          Der Anteil von{" "}
          <UnderlinedIconButton
            LinkComponent="a"
            icon="ExtLink"
            target="_blank"
            isLink
            href={CREATE_LINK_TO_MDB(`/?companyFilter=${coporation}`)}
          >
            {coporation}
          </UnderlinedIconButton>{" "}
          am Gesamtmeinungsmarkt beträgt danach <b>{valueRounded}%</b>.
          Entsprechend der Richtlinien des Medienvielfaltsmonitors wird das
          Unternehmen unter dem Konzernnamen{" "}
          <UnderlinedIconButton
            LinkComponent="a"
            icon="ExtLink"
            target="_blank"
            isLink
            href={CREATE_LINK_TO_MDB(`/?companyFilter=${coporation}`)}
          >
            {coporation}
          </UnderlinedIconButton>{" "}
          geführt.{" "}
        </Typography>
      </div>
    </React.Fragment>
  );
};

ShareholderMVMMeta.propTypes = {
  name: PropTypes.string,
  MVMcoproration: PropTypes.object,
};

export default inject("dataStore")(ShareholderMVMMeta);
