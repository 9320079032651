import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import KEKIcon from "./KEKIcon";
import cx from "classnames";
import Link from "./Link";
const useStyles = makeStyles(theme => ({
  a: {
    textDecoration: "none !important",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: theme.palette.primary.dark,
    },
  },
  iconRegular: {
    width: "18px",
    verticalAlign: "bottom",
    [theme.breakpoints.down("sm")]: {
      width: "17px",
      verticalAlign: "sub",
    },
  },
  iconSmall: {
    width: "15px",
    verticalAlign: "bottom",
    [theme.breakpoints.down("sm")]: {
      width: "14px",
      verticalAlign: "sub",
    },
  },
  span: {
    whiteSpace: "nowrap",
  },
  toggle: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
    margin: theme.spacing(2, 0, 2, 0),
    width: "fit-content",
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: theme.palette.primary.dark,
    },
  },
  noMargin: {
    margin: 0,
  },
}));

const UnderlinedIconButton = props => {
  const {
    className,
    LinkComponent,
    children,
    isLink,
    href,
    icon,
    iconSize,
    noMargin,
    target,
  } = props;
  const classes = useStyles(props);
  const isExternal = icon === "ExtLink";
  return (
    <React.Fragment>
      {isLink ? (
        <LinkComponent
          href={href}
          className={cx(classes.a, { ["link-extern"]: isExternal })}
          target={target}
        >
          <span className={classes.span}>{children}</span>
        </LinkComponent>
      ) : (
        <div
          className={cx(classes.span, classes.toggle, className, {
            [classes.noMargin]: noMargin,
          })}
        >
          {children}{" "}
          <KEKIcon
            icon={icon}
            className={
              iconSize == "small" ? classes.iconSmall : classes.iconRegular
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};

UnderlinedIconButton.propTypes = {};
UnderlinedIconButton.defaultProps = {
  LinkComponent: Link,
};

export default UnderlinedIconButton;
