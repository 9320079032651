import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="m13.283 12.464 6.376-6.36a.917.917 0 0 0 .281-.672c0-.26-.094-.48-.281-.657a.867.867 0 0 0-.657-.28c-.26 0-.479.093-.656.28l-6.376 6.376-6.36-6.376a.917.917 0 0 0-.672-.28.917.917 0 0 0-.672.28.892.892 0 0 0-.266.657c0 .26.089.484.266.672l6.376 6.36-6.376 6.36a.945.945 0 0 0-.266.672c0 .261.089.485.266.672.093.084.198.149.312.196a.902.902 0 0 0 .703 0c.115-.047.224-.112.329-.196l6.36-6.376 6.376 6.376a.876.876 0 0 0 .305.196.902.902 0 0 0 .695 0c.115-.047.219-.112.313-.196a.917.917 0 0 0 .281-.672.917.917 0 0 0-.281-.671l-6.376-6.36z" />
    </SvgIcon>
  );
};

export default CloseIcon;
