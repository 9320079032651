import { hot } from "react-hot-loader";
import React from "react";
import { Router, hashHistory, Route, IndexRoute } from "react-router";
import App from "./pages/_app";
import Overview from "./pages/overview";
import MediaProfile from "./pages/media";
import ShareholderProfile from "./pages/shareholder";
import NotFound from "./pages/notfound";

hashHistory.listen(location => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === "POP") {
      return;
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0);
  });
});

const AppRouter = () => (
  <Router key={Math.random()} history={hashHistory}>
    <Route path="/" component={App}>
      <IndexRoute component={Overview} />
      <Route path="/profile/media/:squuid" component={MediaProfile} />
      <Route
        path="/profile/shareholder/:squuid"
        component={ShareholderProfile}
      />
      <Route path="*" component={NotFound} />
    </Route>
  </Router>
);

export default hot(module)(AppRouter);
