import React, { useRef, useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { inject } from "mobx-react";
import { uniqBy } from "lodash";
import { makeStyles } from "@material-ui/styles";
import InfoIcon from "@material-ui/icons/Info";
import Section from "./Section";
import CollapsibleTree from "./CollapsibleTree";
import ShareholderOperates from "./ShareholderOperates";
import ShareholderProfileMeta from "./ShareholderProfileMeta";
import ShareholderMVMMeta from "./ShareholderMVMMeta";
import Graph from "./Graph";
import GraphLegend from "./Graph/Legend";
import GraphData from "../stores/GraphData";
import Type from "./Type";
import DisclaimerTooltip from "./DisclaimerTooltip";
import Grid from "@material-ui/core/Grid";
import { getLinks, getOperations } from "./utils";

const useStyles = makeStyles(theme => ({
  blue: {
    backgroundColor: theme.palette.background.blue,
  },
  indent: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  info: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
    opacity: 0.5,
  },
  graphContainer: {
    position: "relative",
    borderTop: `3px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.text.white,
    boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%)",
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  null: {},
  noTop: {
    paddingTop: 0,
  },
  top: {
    paddingTop: theme.spacing(6),
  },
}));

export const ShareholderLabelsByMedia = props => {
  const { isAppendix, addS = true } = props;
  let label = "";
  switch (isAppendix) {
    case "online":
      label = "Anbieter";
      break;
    case "tv":
      label = "Veranstalter";
      break;
    case "radio":
      label = "Veranstalter";
      break;
    case "print":
      label = "Herausgeber";
      break;
    default:
      label = "Unternehmen";
      break;
  }
  if (addS) {
    label += "s";
  }
  return label;
};

const Shareholder = inject("dataStore")(props => {
  const { profile, isAppendix, dataStore, entrySquuid } = props;
  const classes = useStyles(props);
  const graph = GraphData.create(
    profile.squuid,
    profile.tree,
    dataStore,
    entrySquuid,
  );
  const isGraphEmpty = graph.nodes.length === 0;
  const isMetaEmpty = typeof profile.street == "undefined";
  const mvmData = dataStore.mkmByCoporationSquuid[profile.squuid];

  const ref = useRef(0);

  const getAllOperations = (squuid, queue) => {
    const links = getLinks(profile.tree, squuid, "holder");
    return links.reduce(
      (acc, link) => {
        const operates = getOperations(profile.tree, link.held.squuid);
        acc = [...acc, ...operates];
        if (!queue.includes(link.held.squuid)) {
          queue.push(link.held.squuid);
          acc = [...acc, ...getAllOperations(link.held.squuid, queue)];
        }
        return acc;
      },
      [...getOperations(profile.tree, squuid)],
    );
  };

  const allOperates = React.useMemo(() => {
    const queue = [profile.squuid];
    return uniqBy(getAllOperations(profile.squuid, queue), "squuid");
  }, [graph, profile.squuid]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          className={isAppendix ? classes.blue : classes.null}
        >
          <Grid item xs={12}>
            <Type type="shareholder" isAppendix={isAppendix} />
            <Typography variant="h2" className={classes.indent}>
              {profile.name}
            </Typography>
            {!isMetaEmpty && (
              <Section className={classes.indent} noTop>
                <ShareholderProfileMeta data={profile} />
              </Section>
            )}
            <Grid item xs={12} sm={9} md={7}>
              {mvmData && (
                <Section className={classes.indent} mdBottom>
                  <ShareholderMVMMeta item={profile} name={profile.name} />
                </Section>
              )}
            </Grid>
          </Grid>
        </Grid>
        {!isGraphEmpty && (
          <Section
            graph
            mdBottom
            className={isAppendix ? classes.noTop : classes.top}
          >
            <Grid container direction="row">
              <Grid item xs={12} md={8} lg={9} ref={ref}>
                <Typography
                  className={classes.indent}
                  variant="h4"
                  gutterBottom
                >
                  <Box display="flex" alignItems="center">
                    Unternehmensstruktur{" "}
                    <DisclaimerTooltip
                      placement="right"
                      content={
                        <React.Fragment>
                          Die ausgewiesenen Unternehmensverhältnisse entsprechen
                          dem medienrechtlichen Kenntnisstand der
                          Landesmedienanstalten. Abweichungen vom
                          Handelsregister sind möglich.
                        </React.Fragment>
                      }
                    >
                      <InfoIcon className={classes.info} />
                    </DisclaimerTooltip>
                  </Box>
                </Typography>
                <div className={classes.graphContainer}>
                  <Graph graph={graph} allOperates={allOperates} />
                  <GraphLegend />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                style={{ maxHeight: "100%", flexGrow: 1 }}
              >
                <ShareholderOperates
                  graph={graph}
                  items={allOperates}
                  isAppendix={isAppendix}
                />
              </Grid>
            </Grid>
          </Section>
        )}
        <Section className={classes.indent} mdBottom>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography variant="h4">
                <Box display="flex" alignItems="center">
                  Inhaber{" "}
                  <DisclaimerTooltip
                    placement="right"
                    content={
                      <React.Fragment>
                        Die in Verbindung mit „Inhabern“ des{" "}
                        <ShareholderLabelsByMedia isAppendix={isAppendix} />{" "}
                        ausgewiesenen Medienangebote werden nicht als
                        Medienangebote des{" "}
                        <ShareholderLabelsByMedia isAppendix={isAppendix} />{" "}
                        ausgewiesen.
                      </React.Fragment>
                    }
                  >
                    <InfoIcon className={classes.info} />
                  </DisclaimerTooltip>
                </Box>
              </Typography>
              <CollapsibleTree
                squuid={profile.squuid}
                tree={profile.tree}
                accessor="held"
                parentSquuids={[profile.squuid]}
              />
            </Grid>
          </Grid>
        </Section>
        <Section className={classes.indent} mdBottom>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Typography variant="h4">
                <Box display="flex" alignItems="center">
                  Beteiligungen{" "}
                  <DisclaimerTooltip
                    placement="right"
                    content={
                      <React.Fragment>
                        Die unter „Beteiligungen“ des{" "}
                        <ShareholderLabelsByMedia isAppendix={isAppendix} />{" "}
                        ausgewiesen Medienangebote werden hier unabhängig von
                        einer Zurechnung gemäß § 62 MStV insgesamt dem{" "}
                        <ShareholderLabelsByMedia
                          isAppendix={isAppendix}
                          addS={false}
                        />{" "}
                        zugeordnet (siehe „zugeordnete Medien“).
                      </React.Fragment>
                    }
                  >
                    <InfoIcon className={classes.info} />
                  </DisclaimerTooltip>
                </Box>
              </Typography>
              <CollapsibleTree
                squuid={profile.squuid}
                tree={profile.tree}
                accessor="holder"
                parentSquuids={[]}
              />
            </Grid>
          </Grid>
        </Section>
      </div>
    </React.Fragment>
  );
});

export default Shareholder;
