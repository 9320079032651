import React from "react";
const isDev = process.env.NODE_ENV === "development";

export const API_BASE_PATH = "/api/v1";
export const API_BASE_URL = isDev
  ? "https://medienvielfaltsmonitor.nand-projects.io"
  : "https://medienvielfaltsmonitor.de";

export const CREATE_LINK_TO_MDB = url => `${API_BASE_URL}${url}`;

export const ATTRIBUTE_LABELS = {
  description: "Beschreibung",
  languages: "Sprachen",
  name: "Name",
  onlineOfferType: "Angebotstyp",
  // operatedBy: '',
  // organization: '',
  platformOperators: "Plattformbetreiber",
  pressEditionsIVW: "IVW Auflage",
  rfBroadcastStatus: "Sendestatus",
  rfCategory: "Programmkategorie",
  rfFreePay: "Verbreitungsart",
  rfLicenseFrom: "Zugelassen von",
  rfLicenseUntil: "Zugelassen bis",
  rfLicensed: "Zulassungsbescheid vom",
  rfParentalAdvisor: "Jugendschutzbeauftragte(r)",
  rfPublicPrivate: "Organisationstyp",
  rfRepresentative: "Vertretungsberechtigte Person",
  rfShoppingChannel: "Telemedium",
  rfStartDate: "Sendebeginn",
  rfStatewide: "Verbreitung",
  rfSupervisingAuthority: "Zuständige Landesmedienanstalt",
  squuid: "UUID",
  state: "Status",
  type: "Typ",
  pressDistributionArea: "Verbreitungsgebiet",
  rfDirector: "Intendanz",
  onlineVisitsIVW: "IVW Visits",
  onlineIVWPI: "IVW PI",
  onlineAsOfDateIVW: "IVW Stand",
  onlineAGOF: "AGOF Unique User",
  onlineAsOfDateAGOF: "AGOF Stand",
  pressEditionsSold: "IVW Auflage",
  pressEditionsEpaper: "IVW EPaperauflage",
  pressAsOfDate: "IVW Stand",
  pressType: "Pressetyp",
  pressMagazineType: "Zeitschriftenart",
  pressKind: "Gattung",
  pressPublishingIntervals: "Turnus (Ausgaben/Jahr)",
};

export const TYPE_LABELS = {
  tv: "Fernsehen",
  print: "Presse",
  online: "Online",
  radio: "Radio",
  shareholder: "Unternehmen",
};

export const CATEGORIES = [
  ["tv", "Fernsehen"],
  ["radio", "Radio"],
  ["online", "Online"],
  ["print", "Presse"],
  ["shareholder", "Unternehmen"],
];

export const getCategoryLabel = key => {
  const label = CATEGORIES.find(c => c[0] === key);
  return label ? label[1] : null;
};

export const LMA_LABELS = {
  KEK: "Kommission zur Ermittlung der Konzentration im Medienbereich",
  DL: "Dienstleister",
  BLM: "Bayerische Landeszentrale für neue Medien",
  brema: "Bremische Landesmedienanstalt",
  LFK: "Landesanstalt für Kommunikation Baden-Württemberg",
  LfM: "Landesanstalt für Medien Nordrhein-Westfalen",
  LMK: "Landeszentrale für Medien und Kommunikation Rheinland-Pfalz",
  LMS: "Landesmedienanstalt Saarland",
  "LPR Hessen": "Hessische Landesanstalt für privaten Rundfunk und neue Medien",
  mabb: "Medienanstalt Berlin-Brandenburg",
  "MA HSH": "Medienanstalt Hamburg/Schleswig-Holstein",
  MMV: "Medienanstalt Mecklenburg-Vorpommern",
  MSA: "Medienanstalt Sachsen-Anhalt",
  NLM: "Niedersächsische Landesmedienanstalt",
  SLM: "Sächsische Landesanstalt für privaten Rundfunk und neue Medien",
  TLM: "Thüringer Landesmedienanstalt",
  "Medienanstalt RLP": "Medienanstalt Rheinland-Pfalz",
  "LFM NRW": "Landesanstalt für Medien Nordrhein-Westfalen",
};

export const PROVIDER_LABELS_BY_MEDIA_TYPE = {
  ON: "Nielsen/Unique Audience",
  TV: "AGF Videoforschung/Zuschaueranteile",
  HF: "agma/ma Audio",
  TZ: "agma/ma Pressemedien",
  PZ: "agma/ma Pressemedien",
};

export const humanizeValue = (key, value) => {
  if (value === undefined) return undefined;
  switch (key) {
    case "rfBroadcastStatus":
      return value.name;
    case "rfPublicPrivate":
      return value === true ? "Öffentlich-rechtlich" : "Privat";
    case "rfStatewide":
      return value === true ? "Bundesweit" : "Regional";
    case "rfFreePay":
      return value === true ? "Free TV" : "Pay TV";
    case "platformOperators":
      return (
        <ul>
          {value.map(v => (
            <li key={v.name}>{v.name}</li>
          ))}
        </ul>
      );
    case "languages":
      return value.map(v => v.name).join(", ");
    case "rfSupervisingAuthority":
      return value.fullName || LMA_LABELS[value.name];
    case "rfCategory":
    case "pressType":
    case "pressMagazineType":
      return value.name;
    default:
      if (!isNaN(value)) return Number(value).toLocaleString("de");
      return value.toString();
  }
};
