import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Link from "./Link";

const styles = theme => ({
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  breadcrumb: {
    display: "flex",
    // justifyContent: 'center',
    alignItems: "center",
    marginBottom: theme.spacing(6),
    "& > *": {
      "&:after": {
        content: '"/"',
        textDecoration: "none",
        margin: theme.spacing(0, 1, 0, 1),
        color: theme.palette.primary.main,
      },
      "&:last-child": {
        "&:after": {
          content: '""',
        },
      },
    },
  },
});

const Breadcrumb = ({ classes, items }) => {
  return (
    <div className={classes.breadcrumb}>
      <a
        className={classes.a}
        href="https://www.kek-online.de/medienkonzentration/"
      >
        <Typography
          variant="body2"
          color="inherit"
          className={classes.link}
          component="span"
        >
          Medienkonzentration
        </Typography>
      </a>
      {items.map(({ label, link }) => {
        if (!link) {
          return (
            <Typography variant="body2" key={label} component="span">
              {label}
            </Typography>
          );
        }
        return (
          <Link key={label} className={classes.a} href={link}>
            <Typography
              variant="body2"
              color="inherit"
              component="span"
              className={classes.link}
            >
              {label}
            </Typography>
          </Link>
        );
      })}
    </div>
  );
};

Breadcrumb.propTypes = {
  items: PropTypes.array,
};

Breadcrumb.defaultProps = {
  items: [],
};

export default withStyles(styles)(Breadcrumb);
