import React, { Component } from "react";
import PropTypes from "prop-types";
import { select, event } from "d3-selection";
import { scaleLinear } from "d3-scale";
import { zoom, zoomIdentity } from "d3-zoom";
import {
  forceSimulation,
  forceLink,
  forceCenter,
  forceManyBody,
  forceCollide,
} from "d3-force";
import { observer, inject } from "mobx-react";
import { action, reaction } from "mobx";
import { withStyles } from "@material-ui/styles";
import Overlay from "./Overlay";
import { COLORS } from "./constants";

const styles = theme => ({
  canvas: { position: "relative", backgroundColor: theme.palette.text.white },
  progressbar: {
    width: 300,
    height: 15,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: `1px solid ${theme.palette.primary.main}`,
  },
  progress: {
    width: 0,
    height: "100%",
    background: theme.palette.primary.main,
  },
});

const PADDING = 20;
const MAX_LEVELS = 4;

const getMaxIterations = numEntities => {
  if (numEntities > 2000) {
    return 450;
  } else if (numEntities > 1000) {
    return 350;
  } else if (numEntities > 500) {
    return 250;
  }
  return 200;
};

@withStyles(styles, { withTheme: true })
@inject("dataStore")
@observer
class Canvas extends Component {
  constructor(props) {
    super(props);
    const { graph } = props;
    const numEntities = Math.max(graph.nodes.length, graph.links.length);
    this.maxIterations = getMaxIterations(numEntities);
    this.ticking = false;
    this.tickCnt = 0;

    this.hideGraph = () => {
      this.canvas.style.visibility = "hidden";
      this.overlay.overlay.style.visibility = "hidden";
      this.progress.parentNode.style.visibility = "visible";
    };

    this.showGraph = () => {
      this.canvas.style.visibility = "visible";
      this.overlay.overlay.style.visibility = "visible";
      this.progress.parentNode.style.visibility = "hidden";
    };
  }

  componentDidMount() {
    const { graph, width, height } = this.props;
    const { nodes, links } = graph;

    this.canvasContext = this.canvas.getContext("2d");
    this.zoomHandler = zoom().scaleExtent([0.5, 2]).on("zoom", this.handleZoom);

    select(this.container).call(this.zoomHandler);

    this.xScale = scaleLinear()
      .domain([0, MAX_LEVELS])
      .range([PADDING, width - PADDING * 2]);
    this.xStrengthScale = scaleLinear()
      .domain([0, MAX_LEVELS])
      .clamp(true)
      .range([3, 0.1]);
    this.yStrengthScale = scaleLinear()
      .domain([0, MAX_LEVELS])
      .clamp(true)
      .range([1, 0.1]);

    this.simulation = forceSimulation(nodes)
      .velocityDecay(0.6)
      .force("charge", forceManyBody().strength(-60))
      .force(
        "collide",
        forceCollide().radius(node => node.radius + 5),
      )
      .force(
        "link",
        forceLink(links)
          .strength(1)
          .id(link => link.id),
      )
      .force("center", forceCenter(width / 2, height / 2))
      .on("tick", this.handleTick)
      .on("end", this.handleEnd)
      .stop();

    this.reactions = [
      reaction(
        () => this.props.graph.hoveredNode,
        () => this.draw(),
      ),
      reaction(
        () => this.props.dataStore.mediaTypeFilter,
        () => this.draw(),
      ),
      reaction(
        () => this.props.graph.zoom,
        () => this.draw(),
      ),
    ];
    this.hideGraph();
    window.requestAnimationFrame(this.updateSimulation);
  }

  updateSimulation = () => {
    if (this.tickCnt < this.maxIterations) {
      this.simulation.tick();
      this.handleTick();
      window.requestAnimationFrame(this.updateSimulation);
    } else {
      this.handleEnd();
    }
  };

  componentDidUpdate(lastProps) {
    this.canvasContext = this.canvas.getContext("2d");

    const { graph, width, height } = this.props;
    const { nodes, links } = graph;

    this.xScale.range([PADDING, width - PADDING * 2]);

    this.simulation.nodes(nodes);
    this.simulation.force("link").links(links);

    this.simulation
      .force("center")
      .x(width / 2)
      .y(height / 2);
    this.simulation.tick();
    this.draw();
    this.tickCnt = 0;
    if (nodes != lastProps.graph.nodes) {
      this.hideGraph();
      this.simulation.alpha(1).restart().stop();
      this.tickCnt = 0;
    }
  }

  componentWillUnmount() {
    if (this.simulation != null) {
      this.simulation.stop();
      this.simulation.on("tick", null);
    }
    this.reactions.forEach(r => r());
  }

  handleTick = () => {
    this.ticking = true;
    this.tickCnt += 1;
    this.progress.style.width = `${(this.tickCnt / this.maxIterations) * 100}%`;
  };

  handleEnd = () => {
    this.ticking = false;
    this.draw();
    this.showGraph();
    const { graph, width, height } = this.props;
    const node = graph.nodes.find(n => n.id === graph.entrySquuid);
    select(this.container).call(
      this.zoomHandler.transform,
      zoomIdentity.scale(1).translate(width / 2 - node.x, height / 2 - node.y),
    );
  };

  handleZoom = action(() => {
    this.props.graph.changeZoom({
      ...event.transform,
      transform: event.transform.toString(),
    });
    this.overlay.g.setAttribute("transform", event.transform.toString());
  });

  draw() {
    const { width, height, graph, theme, allOperates, dataStore } = this.props;
    const { nodes, links, zoom } = graph;

    const isMediaTypeFilterActive = dataStore.mediaTypeFilter.length > 0;
    const { x, y, k } = zoom;

    this.canvasContext.save();

    this.canvasContext.clearRect(0, 0, width, height);
    this.canvasContext.translate(x, y);
    this.canvasContext.scale(k, k);

    this.canvasContext.fillStyle = theme.palette.text.white;
    this.canvasContext.fillRect(0, 0, this.canvas.width, this.canvas.height);
    const activeLinks = graph.activeLinks;
    // Draw lines
    links.forEach(link => {
      this.canvasContext.beginPath();
      this.canvasContext.moveTo(link.source.x, link.source.y);
      this.canvasContext.lineTo(link.target.x, link.target.y);
      const isActive = activeLinks.includes(link);
      this.canvasContext.strokeStyle = isActive
        ? theme.palette.primary.main
        : theme.palette.grey[300];
      this.canvasContext.stroke();
    });

    // Draw nodes
    // @TODO Improve performance by drawing by node/media type
    nodes.forEach(node => {
      this.canvasContext.beginPath();

      if (node.type === "shareholder") {
        this.canvasContext.moveTo(node.x + node.radius, node.y);
        this.canvasContext.arc(node.x, node.y, node.radius, 0, 2 * Math.PI);

        this.canvasContext.fillStyle = COLORS.shareholders;
      } else {
        const left = node.x + node.size / 2;
        const top = node.y - node.size / 2;
        const bottom = top + node.size;
        const right = left - node.size;
        const color = COLORS[node.type];
        this.canvasContext.moveTo(right, top);
        this.canvasContext.lineTo(right, bottom);
        this.canvasContext.lineTo(left, bottom);
        this.canvasContext.lineTo(left, top);
        this.canvasContext.lineTo(right, top);
        const isActive =
          graph.nodesOnPathOfHoveredNode.findIndex(n => n.id === node.id) > -1;
        this.canvasContext.fillStyle = isActive ? "#000000" : color;
      }

      this.canvasContext.fill();
      this.canvasContext.lineWidth = 2;
      this.canvasContext.strokeStyle = "#FFFFFF";
      this.canvasContext.stroke();
    });

    this.canvasContext.restore();

    if (this.overlay == null || this.overlay.labels == null) {
      return;
    }

    for (let [id, ref] of this.overlay.labels) {
      const node = ref.props.node;
      const entryOrOnPath = graph.nodesOnPathOfHoveredNode.includes(node);
      const viewState = {
        belongsTo: allOperates.findIndex(n => n.squuid === node.squuid) > -1,
        idle: !graph.hoveredNode && !isMediaTypeFilterActive,
        active:
          isMediaTypeFilterActive && !graph.hoveredNode
            ? dataStore.mediaTypeFilter === node.type &&
              !graph.hoveredNode &&
              node.links.some(
                l =>
                  allOperates.findIndex(n => n.squuid === l.target.squuid) > -1,
              )
            : entryOrOnPath,

        hideLabel: isMediaTypeFilterActive && !entryOrOnPath,
      };
      ref.props.viewState.setViewState(viewState);
      select(ref.element).attr("transform", `translate(${node.x}, ${node.y})`);
    }
  }

  render() {
    const { width, height, graph, classes, allOperates } = this.props;
    return (
      <div
        className={classes.canvas}
        ref={ref => {
          this.container = ref;
        }}
      >
        <canvas
          ref={ref => {
            this.canvas = ref;
          }}
          width={width}
          height={height}
        />
        <Overlay
          ref={ref => (this.overlay = ref)}
          width={width}
          height={height}
          simulation={this.simulation}
          graph={graph}
          allOperates={allOperates}
        />
        <div className={classes.progressbar}>
          <div
            className={classes.progress}
            ref={ref => (this.progress = ref)}
          />
        </div>
      </div>
    );
  }
}

Canvas.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  graph: PropTypes.object,
  classes: PropTypes.object,
  theme: PropTypes.object,
  allOperates: PropTypes.array,
};

export default Canvas;
