import React from "react";
import PropTypes from "prop-types";
import ExtLinkIcon from "./Icons/ExtLinkIcon";
import IntLinkIcon from "./Icons/IntLinkIcon";
import CollapseIcon from "./Icons/CollapseIcon";
import ExpandIcon from "./Icons/ExpandIcon";
import CloseIcon from "./Icons/CloseIcon";

const KEKIcon = props => {
  const { icon } = props;
  switch (icon) {
    case "ExtLink":
      return <ExtLinkIcon {...props} />;
    case "Collapse":
      return <CollapseIcon {...props} />;
    case "Expand":
      return <ExpandIcon {...props} />;
    case "IntLink":
      return <IntLinkIcon {...props} />;
    default:
      return <CloseIcon {...props} />;
  }
};

KEKIcon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default KEKIcon;
