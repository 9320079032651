import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const IntLinkIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M14.172 6.375a.67.67 0 0 0-.492-.203.62.62 0 0 0-.477.203.647.647 0 0 0-.219.492c0 .193.073.357.22.492l4.171 4.266H4.765c-.197 0-.37.07-.515.21a.663.663 0 0 0-.219.493c0 .198.073.365.219.5a.733.733 0 0 0 .516.203h12.562l-4.172 4.266a.68.68 0 0 0-.203.5.68.68 0 0 0 .203.5.707.707 0 0 0 .219.148.62.62 0 0 0 .25.055.655.655 0 0 0 .266-.055.935.935 0 0 0 .234-.148l5.844-5.985-5.797-5.937z" />
    </SvgIcon>
  );
};

export default IntLinkIcon;
