import React from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import cx from "classnames";
import { TYPE_LABELS } from "../constants";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  flag: {
    minHeight: "51px",
    marginBottom: theme.spacing(7),
    position: "relative",
  },
  flagTranslate: {
    minHeight: "51px",
    marginBottom: theme.spacing(4),
    position: "relative",
    transform: "translateY(-50%)",
  },
  coverWhite: {
    backgroundColor: theme.palette.text.white,
    position: "absolute",
    width: "30px",
    height: "80px",
    display: "block",
    transform: "rotate(25deg)",
    top: 2,
    left: "232px",
  },
  coverBlue: {
    backgroundColor: theme.palette.background.blue,
    position: "absolute",
    width: "30px",
    height: "80px",
    display: "block",
    transform: "rotate(25deg)",
    top: 2,
    left: "232px",
  },
  type: {
    position: "absolute",
    top: "16px",
    left: "24px",
    color: theme.palette.text.white,
    marginBottom: 0,
  },
}));

const ShareholderLabelsByMedia = props => {
  const { isAppendix } = props;
  switch (isAppendix) {
    case "online":
      return "Anbieter";
    case "tv":
      return "Veranstalter";
    case "radio":
      return "Veranstalter";
    case "print":
      return "Herausgeber";
    default:
      return "Anbieter";
  }
};

const Type = props => {
  const classes = useStyles(props);
  const { isAppendix } = props;
  const theme = useTheme();

  const flagColorsByType = theme.palette.types;
  const color = flagColorsByType[props.type] || flagColorsByType.shareholder;

  return (
    <div className={isAppendix ? classes.flagTranslate : classes.flag}>
      <svg width="290" height="51" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M0 0h290l-28.912 51H0z" />
      </svg>
      <Typography className={cx(classes.type)} variant="h5">
        {isAppendix ? (
          <ShareholderLabelsByMedia isAppendix={isAppendix} />
        ) : (
          TYPE_LABELS[props.type]
        )}
      </Typography>
    </div>
  );
};

export default Type;
