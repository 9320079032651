import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import cx from "classnames";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { getCategoryLabel } from "../../constants";
import { findLastIndex } from "lodash";

const useStyles = makeStyles(theme => ({
  borderBox: {
    backgroundColor: "#B6B7BB",
    border: `2px solid ${theme.palette.text.white}`,
    boxShadow: `0 0 0 2px ${theme.palette.gray.medium}`,
    width: "10px",
    height: "10px",
    marginRight: theme.spacing(1),
  },
  legend: {
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 44%, rgba(255,255,255,1) 100%)",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      bottom: 0,
      left: 0,
      flexDirection: "column",
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      width: "fit-content",
      background:
        "radial-gradient(bottom left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 80%)",
    },
  },
  typeBox: {
    width: "10px",
    height: "10px",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0.5),
    },
  },
  xsLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

const order = ["tv", "radio", "print", "online", "shareholder"];

const Legend = props => {
  const classes = useStyles(props);
  const theme = useTheme();
  const types = Object.keys(theme.palette.types).sort((a, b) => {
    const indexA = order.indexOf(a);
    const indexB = order.indexOf(b);
    if (indexA > indexB) return 1;
    if (indexA < indexB) return -1;
    return 0;
  });
  return (
    <div className={classes.legend}>
      <Box display="flex" alignItems="center">
        <Box className={classes.borderBox} />
        <Typography variant="caption" className={classes.xsLabel}>
          Zugeordnete Medien
        </Typography>
      </Box>
      {types.map(type => {
        const color = type === "shareholder" ? "#B6B7BB" : `types.${type}`;
        const borderRadius = type === "shareholder" ? "50%" : "none";
        return (
          <Box key={type} display="flex" alignItems="center">
            <Box
              className={classes.typeBox}
              bgcolor={color}
              borderRadius={borderRadius}
            />
            <Typography variant="caption" className={classes.xsLabel}>
              {getCategoryLabel(type)}
            </Typography>
          </Box>
        );
      })}
      {null && (
        <Box display="flex" alignItems="center">
          <Box bgcolor="primary.main" width={10} height={10} mr={1} />
          <Typography variant="caption">Selektiert</Typography>
        </Box>
      )}
    </div>
  );
};

export default Legend;
