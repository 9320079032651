import React, { useState, useRef, useEffect } from "react";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/styles";
import PropTypes from "prop-types";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Link from "./Link";
import Box from "@material-ui/core/Box";
import InfoIcon from "@material-ui/icons/Info";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DisclaimerTooltip from "./DisclaimerTooltip";
import IntLinkIcon from "./Icons/IntLinkIcon";
import { uniqBy, sortBy } from "lodash";
import { ShareholderLabelsByMedia } from "./Shareholder";

const useStyles = makeStyles(theme => ({
  angebot: {
    whiteSpace: "nowrap",
    display: "inline-flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  disclaimer: {
    marginBottom: theme.spacing(3),
  },
  flexContainer: {
    height: "100%",
    maxHeight: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(3),
    position: "relative",
    marginRight: theme.spacing(3),
  },
  info: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
    opacity: 0.5,
  },
  select: {
    backgroundColor: theme.palette.text.white,
    marginBottom: theme.spacing(3),
    "&:hover": {
      backgroundColor: theme.palette.text.white,
    },
  },
  scrollShadow: {
    pointerEvents: "none",
    position: "absolute",
    left: 0,
    zIndex: 100,
    width: "100%",
    height: "70px",
    bottom: 0,
    background:
      "linear-gradient(0deg, rgba(245,249,252,1) 15%, rgba(245,249,252,0) 100%)",
  },
  headContainer: {
    display: "flex",
    flexDirection: "column",
  },
  type: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.grey[300],
    marginRight: theme.spacing(1),
  },
}));

const Items = inject("dataStore")(props => {
  const { items, dataStore, onMouseEnter, onMouseLeave } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  if (!items) return "Kein Medienangebot vorhanden";

  const createMouseEnterHandler = o => () => {
    onMouseEnter(o);
  };
  const createMouseLeaveHandler = o => () => {
    onMouseLeave(o);
  };
  return (
    <>
      {items.map(o => (
        <Link
          key={o.squuid}
          to={`/profile/media/${o.squuid}`}
          onMouseEnter={createMouseEnterHandler(o)}
          onMouseLeave={createMouseLeaveHandler(o)}
        >
          <Typography className={classes.angebot} component="span" body="body1">
            <Box position="relative" width={24} height={14}>
              <Box
                top={3}
                left={3}
                position="absolute"
                width={10}
                height={10}
                bgcolor={theme.palette.types[o.type]}
              />
              <Box
                top={0}
                position="absolute"
                width={16}
                height={16}
                bgcolor="transparent"
                border={2}
                borderColor={theme.palette.types[o.type]}
              />
            </Box>
            {o.name} <IntLinkIcon />
          </Typography>
        </Link>
      ))}
      <div style={{ minHeight: 20 }} />
    </>
  );
});

// dummy data – there is TYPE_LABELS in constants yet these include shareholder
const OPTIONS = ["tv", "radio", "print", "online"];
const OPTION_LABELS = {
  tv: "Fernsehen",
  radio: "Radio",
  print: "Presse",
  online: "Online",
};

const ShareholderOperates = props => {
  const { items, graph, dataStore, isAppendix } = props;
  const { mediaTypeFilter } = dataStore;
  const classes = useStyles(props);
  const absoluteContainerRef = React.useRef(null);

  const [isScrollable, setIsScrollable] = useState(false);

  const handleChange = event => {
    dataStore.setMediaTypeFilter(event.target.value);
  };

  const availableOptions = React.useMemo(
    () => OPTIONS.filter(o => items.findIndex(i => i.type === o) > -1),
    [items],
  );

  const filterdItems = React.useMemo(
    () =>
      sortBy(
        uniqBy(
          items.filter(
            i => {
              if (mediaTypeFilter.length > 0) {
                if (i.type !== mediaTypeFilter) return false;
              }
              return true;
            },
            i => i.squuid,
          ),
        ),
        [i => OPTIONS.indexOf(i.type), i => i.name.toLowerCase()],
      ),
    [mediaTypeFilter, graph],
  );

  React.useEffect(() => {
    if (absoluteContainerRef.current) {
      const { offsetHeight, scrollHeight } = absoluteContainerRef.current;
      setIsScrollable(offsetHeight < scrollHeight);
    }
  }, [absoluteContainerRef]);

  React.useEffect(() => {
    const handleResize = () => {
      const { offsetHeight, scrollHeight } = absoluteContainerRef.current;
      setIsScrollable(offsetHeight < scrollHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [absoluteContainerRef]);

  const handleMouseEnterItem = i => {
    graph.hoverNode(graph.nodes.find(n => n.squuid === i.squuid));
  };

  const handleMouseLeaveItem = i => {
    graph.hoverNode(null);
  };

  return (
    <React.Fragment>
      <div className={classes.flexContainer}>
        <div className={classes.headContainer}>
          <Typography variant="h4" gutterBottom>
            <Box display="flex" alignItems="center">
              Zugeordnete Medien
            </Box>
          </Typography>
          <Typography variant="caption" pb={5} className={classes.disclaimer}>
            <b>{filterdItems.length}</b> der Medienangebote innerhalb der
            Struktur werden dem Unternehmen aufgrund der Eigenschaft als{" "}
            <ShareholderLabelsByMedia isAppendix={isAppendix} /> des Angebotes
            oder aufgrund gesellschaftsrechtlicher Beteiligung zugeordnet.
          </Typography>
          <Typography variant="caption"> Medientyp </Typography>
          <FormControl variant="filled">
            <Select
              className={classes.select}
              value={mediaTypeFilter}
              displayEmpty
              disabled={!items}
              onChange={handleChange}
              inputProps={{
                name: "option",
                id: "optinon-native-helper",
              }}
            >
              <MenuItem value="">Alle Medientypen</MenuItem>
              {availableOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {OPTION_LABELS[option]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexGrow: 1,
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            minHeight: 100,
          }}
        >
          <div
            ref={absoluteContainerRef}
            style={{
              position: "absolute",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Items
              items={filterdItems}
              onMouseEnter={handleMouseEnterItem}
              onMouseLeave={handleMouseLeaveItem}
            />
          </div>
        </div>
        {isScrollable && <div className={classes.scrollShadow} />}
      </div>
    </React.Fragment>
  );
};

ShareholderOperates.propTypes = {};

export default inject("dataStore")(observer(ShareholderOperates));
