import React from "react";
import Typography from "@material-ui/core/Typography";
import Breadcrumb from "../components/Breadcrumb";
import Link from "../components/Link";

const NotFound = () => (
  <React.Fragment>
    <Breadcrumb />
    <Typography variant="h2">
      Es tut uns leid, die von Ihnen gesuchte Seite konnte nicht gefunden
      werden.
    </Typography>
    <Link to="/">
      <Typography>Zurück zur Mediendatenbank</Typography>
    </Link>
  </React.Fragment>
);

export default NotFound;
