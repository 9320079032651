import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const DisclaimerTooltip = props => {
  const { content, placement } = props;
  return (
    <Tooltip
      arrow
      placement={placement}
      title={<Typography variant="body2">{content}</Typography>}
      {...props}
    />
  );
};

DisclaimerTooltip.propTypes = {};

export default DisclaimerTooltip;
