/* eslint-disable no-console */
import React from "react";
import { Provider as MobxProvider, observer, inject } from "mobx-react";
import { withStyles, ThemeProvider } from "@material-ui/styles";

import theme from "../theme";
import DataStoreInstance from "../stores/DataStore";
import CssBaseline from "@material-ui/core/CssBaseline";

const classes = theme => ({
  "@global": {
    html: {
      [theme.breakpoints.down("md")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("md")]: {
        fontSize: 16,
      },
    },
  },
});

@withStyles(classes)
@inject("dataStore")
@observer
class App extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <section>
        <div>{this.props.children}</div>
      </section>
    );
  }
}

const ThemedAndConnectedChatApp = props => {
  if (window) {
    window.store = DataStoreInstance;
  }
  React.useEffect(() => {
    DataStoreInstance.resetMediaTypeFilter();
  }, [props.params.squuid]);
  return (
    <ThemeProvider theme={theme}>
      <MobxProvider dataStore={DataStoreInstance}>
        <React.Fragment>
          <CssBaseline />
          <App {...props} />
        </React.Fragment>
      </MobxProvider>
    </ThemeProvider>
  );
};

ThemedAndConnectedChatApp.displayName = "ThemedAndConnectedChatApp";

export default ThemedAndConnectedChatApp;
