import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";

const styles = theme => ({
  btn: {
    textTransform: "uppercase",
  },
  active: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
});

const FilterButton = ({ classes, className, isActive, ...props }) => (
  <Button
    className={cx(classes.btn, className, { [classes.active]: isActive })}
    variant="contained"
    color="primary"
    {...props}
  />
);

FilterButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

FilterButton.defaultProps = {
  className: null,
  isActive: false,
};

export default withStyles(styles)(FilterButton);
