import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom";
import Router from "./Router";

if (global.window) {
  window.__NAND_KEK_APP_VERSION =
    process.env.npm_package_version || process.env.APP_VERSION;
}

ReactDOM.render(
  <Router />,
  document.getElementById("kek-online-mediendatenbank"),
);

if (module.hot) module.hot.accept();
