import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { observer, inject } from "mobx-react";
import { hashHistory } from "react-router";
import { withStyles } from "@material-ui/styles";

import { NODE_CIRCLE_RADIUS } from "./constants";
import OverlayLabel from "./OverlayLabel";
import { sortBy } from "lodash";

const styles = () => ({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    cursor: "move",
  },
});

export class NodeViewState {
  @observable idle = true;
  @observable active = true;
  @observable belongsTo = false;
  @observable hideLabel = true;

  constructor(data) {
    Object.assign(this, data);
  }

  @action setValue(key, value) {
    this[key] = value;
  }

  @action setViewState(newState) {
    Object.keys(newState).forEach(key => {
      this.setValue(key, newState[key]);
    });
  }
}

@withStyles(styles)
@inject("dataStore")
class Overlay extends Component {
  getNode(event) {
    const { simulation, graph } = this.props;
    const { zoom } = graph;

    let top = 0,
      left = 0;
    let element = this.overlay;

    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    const scale = 1 / zoom.k;

    const x = (event.pageX - left - zoom.x) * scale;
    const y = (event.pageY - top - zoom.y) * scale;

    return simulation.find(x, y, NODE_CIRCLE_RADIUS + 20);
  }

  handleClick = event => {
    const { graph } = this.props;
    const { activatedNode } = graph;
    const node = this.getNode(event);

    if (node && activatedNode === node.id) {
      hashHistory.push(
        `/profile/${node.type === "shareholder" ? "shareholder" : "media"}/${
          node.squuid
        }`,
      );
    }

    graph.activateNode(node);
  };

  handleMouseMove = event => {
    const { graph } = this.props;
    const node = this.getNode(event);
    graph.hoverNode(node);
  };

  handleMouseLeave = () => {
    const { graph } = this.props;
    graph.hoverNode(null);
  };

  get nodeData() {
    const { graph, dataStore, allOperates } = this.props;
    const { nodes } = graph;
    const isMediaTypeFilterActive = dataStore.mediaTypeFilter.length > 0;
    return nodes.map(node => {
      const entryOrOnPath = graph.hoveredNode
        ? graph.nodesOnPathOfHoveredNode.includes(node)
        : graph.entrySquuid === node.id;
      return {
        node,
        viewState: new NodeViewState({
          belongsTo: allOperates.findIndex(n => n.squuid === node.squuid) > -1,
          idle: !graph.hoveredNode && !isMediaTypeFilterActive,
          active:
            isMediaTypeFilterActive && !graph.hoveredNode
              ? dataStore.mediaTypeFilter === node.type &&
                !graph.hoveredNode &&
                node.links.some(
                  l =>
                    allOperates.findIndex(n => n.squuid === l.target.squuid) >
                    -1,
                )
              : entryOrOnPath,

          hideLabel: isMediaTypeFilterActive && !entryOrOnPath,
        }),
      };
    });
  }

  get sortedNodes() {
    const { graph } = this.props;
    const { activatedNode, nodeForPathHighlighting } = graph;
    return sortBy(this.nodeData, [
      n => n.node.id === activatedNode || n.node.id === nodeForPathHighlighting,
      n => n.active,
      n => !n.hideLabel,
    ]);
  }

  get isGraphRelatedToMedia() {
    const { graph } = this.props;
    return (
      graph.nodes.find(n => n.id === graph.entrySquuid).type !== "shareholder"
    );
  }

  render() {
    const { width, height, graph, classes } = this.props;
    const { zoom } = graph;
    this.labels = new Map();
    const labelScale = 1 / zoom.k;
    return (
      <div
        className={classes.overlay}
        ref={ref => {
          this.overlay = ref;
        }}
        onClick={this.handleClick}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
      >
        <svg width={width} height={height} ref={ref => (this.svg = ref)}>
          <g transform={zoom.transform} ref={ref => (this.g = ref)}>
            {this.sortedNodes.map(node => (
              <OverlayLabel
                key={node.node.id}
                ref={ref => {
                  ref != null && this.labels.set(node.node.id, ref);
                }}
                viewState={node.viewState}
                node={node.node}
                scale={labelScale}
                focus={false}
                visible={true}
                highlightContainerRef={this.highlightContainerRef}
              />
            ))}
            <g
              ref={ref => (this.highlightContainerRef = ref)}
              id="highlight"
            ></g>
          </g>
        </svg>
      </div>
    );
  }
}

Overlay.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  graph: PropTypes.object,
  classes: PropTypes.object,
  dataStore: PropTypes.object,
  simulation: PropTypes.object,
  allOperates: PropTypes.array,
};

export default Overlay;
