import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import cx from "classnames";
import Link from "../Link";
import IntLinkIcon from "../Icons/IntLinkIcon";
import CollapseIcon from "../Icons/CollapseIcon";
import ExpandIcon from "../Icons/ExpandIcon";

const useStyles = makeStyles(theme => ({
  alignB: {
    verticalAlign: "bottom",
  },
  node: {
    // listStyle: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    "&:last-child": {
      borderBottom: "none",
    },
    // '&:first-child': {
    //   borderBottom: `1px solid ${theme.palette.grey[300]}`,
    // },
  },
  nodeData: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 1),
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  foldBtn: {
    cursor: "pointer",
    marginBottom: 0,
    minWidth: theme.spacing(3),
    width: theme.spacing(3),
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  value: {
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(6),
      minWidth: theme.spacing(6),
    },
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(8),
      minWidth: theme.spacing(8),
    },
    textAlign: "center",
    background: "transparent",
    color: theme.palette.common.white,
    padding: theme.spacing(0.5, 1),
    borderRadius: 0,
    overflow: "hidden",
    fontWeight: 500,
    position: "relative",
    "&:before": {
      background: theme.palette.grey[400],
      position: "absolute",
      zIndex: -2,
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      content: '""',
    },
  },
  actualValue: {
    position: "absolute",
    background: theme.palette.primary.main,
    height: "100%",
    top: 0,
    left: 0,
    zIndex: -1,
  },
  nameCol: {
    flexGrow: 1,
  },
  name: {
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  link: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
    width: 130,
    minWidth: 130,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  isHidden: {
    opacity: 0,
  },
  operations: {
    marginLeft: theme.spacing(14),
    marginBottom: theme.spacing(0.5),
  },
  operation: {
    display: "flex",
    alignItems: "center",
  },
  mark: {
    width: 10,
    height: 10,
    backgroundColor: props => theme.palette.types[props.type],
    marginRight: theme.spacing(1),
  },
}));

const Operation = props => {
  const classes = useStyles(props);
  return (
    <Typography className={classes.operation} variant="body2">
      <mark className={classes.mark} />
      {props.name}
    </Typography>
  );
};

Operation.propTypes = {
  name: PropTypes.string.isRequired,
};

const Node = props => {
  const {
    link,
    name,
    hasChildren,
    squuid,
    operations,
    initialOpen,
    hideUnfold,
  } = props;
  const [isOpen, setIsOpen] = useState(initialOpen);
  const classes = useStyles(props);
  const capitalShares = link.capitalShares.toFixed(1);

  return (
    <li className={classes.node}>
      <div className={classes.nodeData}>
        <Typography
          component="span"
          onClick={() => setIsOpen(!isOpen)}
          className={cx(
            { [classes.isHidden]: !hasChildren || hideUnfold },
            classes.foldBtn,
          )}
        >
          {isOpen ? (
            <CollapseIcon className={classes.alignB} />
          ) : (
            <ExpandIcon className={classes.alignB} />
          )}
        </Typography>
        <Typography component="span" variant="body2" className={classes.value}>
          {capitalShares}%
          <div
            className={classes.actualValue}
            style={{ width: `${capitalShares}%` }}
          />
        </Typography>
        <div className={classes.nameCol}>
          <Link to={`/profile/shareholder/${squuid}`}>
            <Typography component="span" className={classes.name}>
              {name} <IntLinkIcon className={classes.icon} />
            </Typography>
          </Link>
        </div>
      </div>
      <div className={classes.operations}>
        {operations.map(o => (
          <Operation key={o.squuid} name={o.name} type={o.type} />
        ))}
      </div>
      {isOpen && props.children}
    </li>
  );
};

Node.propTypes = {
  link: PropTypes.object.isRequired,
  operations: PropTypes.array.isRequired,
  hasChildren: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  squuid: PropTypes.string.isRequired,
  name: PropTypes.string,
  initialOpen: PropTypes.bool,
  hideUnfold: PropTypes.bool,
};

Node.defaultProps = {
  hideUnfold: false,
  initialOpen: false,
};

export default Node;
