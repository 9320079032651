import { createTheme } from "@material-ui/core/styles";

const spacing = (mult = 1) => `${8 * mult}px`;

const XLIGHT_BLUE = "#F5F9FC";
const LIGHT_BLUE = "#e4edf7";
const MEDIUM_BLUE = "#005bbb";
const DARK_BLUE = "#001049";

const LIGHT_GRAY = "#f0f2f3";
const MEDIUM_GRAY = "#B6B7BB";
const DARK_GRAY = "#231F20";

const WHITE = "#FFFFFF";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 650,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: LIGHT_BLUE,
      main: MEDIUM_BLUE,
      dark: DARK_BLUE,
      contrastText: MEDIUM_BLUE,
    },
    background: {
      default: WHITE,
      blue: XLIGHT_BLUE,
    },
    text: {
      primary: DARK_GRAY,
      white: WHITE,
    },
    types: {
      tv: "#a055af",
      online: "#3a97ab",
      shareholder: "#001049",
      print: "#67afff",
      radio: "#03cbad",
    },
    typesLight: {
      tv: "#D39EDE",
      radio: "#83FAE8",
      print: "#98C9FF",
      online: "#93C9D4",
    },
    gray: {
      light: LIGHT_GRAY,
      medium: MEDIUM_GRAY,
    },
  },
  typography: {
    fontFamily: "montserrat, sans-serif",
    body1: {
      fontSize: "1.1rem",
      fontWeight: 400,
      "@media (min-width:650px)": {
        fontSize: "1.2rem",
      },
      "@media (min-width:960px)": {
        fontSize: "1rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "1rem",
      },
    },
    body2: {
      fontSize: "0.9rem",
    },
    caption: {
      fontSize: "1rem",
      lineHeight: 1.5,
      "@media (min-width:650px)": {
        fontSize: "1.1rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.8rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "0.85rem",
      },
    },
    h1: {
      fontSize: "2.2rem",
      fontWeight: 700,
      color: DARK_BLUE,
      "@media (min-width:650px)": {
        fontSize: "2.4rem",
      },
      "@media (min-width:960px)": {
        fontSize: "2.5rem",
      },
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 700,
      color: "#001049",
      marginBottom: spacing(2),
      lineHeight: 1.3,
    },
    h4: {
      fontSize: "1.15rem",
      lineHeight: 1.5,
      fontWeight: 700,
      color: DARK_BLUE,
      "@media (min-width:650px)": {
        fontSize: "1.25rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.95rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "1.05rem",
      },
    },
    h5: {
      textTransform: "uppercase",
      fontSize: "1.1rem",
      marginBottom: spacing(1),
      fontWeight: 400,
      "@media (min-width:650px)": {
        fontSize: "1.2rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.9rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "1rem",
      },
    },
    h6: {
      fontSize: "1rem",
      "@media (min-width:650px)": {
        fontSize: "1.2rem",
      },
      "@media (min-width:960px)": {
        fontSize: "0.9rem",
      },
      "@media (min-width:1280px)": {
        fontSize: "0.9rem",
      },
      fontWeight: 700,
      color: "#202020",
    },
  },
});

Object.assign(theme, {
  overrides: {
    MuiButton: {
      root: {
        // same as body1 could this be addressed somehow (theme.typography.body1)?
        fontSize: "1rem",
        fontWeight: 400,
        "@media (min-width:650px)": {
          fontSize: "1.2rem",
        },
        "@media (min-width:960px)": {
          fontSize: "0.9rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "1rem",
        },
        textTransform: "none",
        paddingTop: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
      },
      containedSecondary: {
        boxShadow: "none",
        backgroundColor: XLIGHT_BLUE,
        color: DARK_BLUE,
        padding: 0,
        borderRadius: 0,
        lineHeight: 1,
        "&:hover": {
          boxShadow: "none",
          backgroundColor: LIGHT_BLUE,
        },
        "& $label span": {
          padding: theme.spacing(1, 1, 1, 1),
        },
        "& $startIcon": {
          backgroundColor: DARK_BLUE,
          color: LIGHT_BLUE,
          margin: 0,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
      containedPrimary: {
        boxShadow: "none",
        borderRadius: "0",
        backgroundColor: LIGHT_BLUE,
        color: MEDIUM_BLUE,
        "&:hover": {
          backgroundColor: DARK_BLUE,
          color: LIGHT_BLUE,
        },
        "&:active": {
          boxShadow: "none",
        },
      },
      text: {
        textTransform: "none",
        borderRadius: 0,

        "&$sizeSmall": {
          borderBottom: "1px solid currentColor",
        },
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
      },
    },
    MuiFilledInput: {
      inputMarginDense: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      root: {
        // same as body1 could this be addressed somehow (theme.typography.body1)?
        backgroundColor: LIGHT_BLUE,
        fontSize: "1rem",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        "&.Mui-disabled": {
          backgroundColor: XLIGHT_BLUE,
          "&:hover": {
            backgroundColor: XLIGHT_BLUE,
          },
        },
        "@media (min-width:650px)": {
          fontSize: "1.2rem",
        },
        "@media (min-width:960px)": {
          fontSize: "0.9rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "1rem",
        },
      },
    },
    MuiSelect: {
      root: {
        paddingTop: "8px",
        color: MEDIUM_BLUE,
        "&:hover": {
          color: DARK_BLUE,
        },
        "&.Mui-disabled": {
          color: MEDIUM_GRAY,
          "&:hover": {
            backgroundColor: XLIGHT_BLUE,
          },
        },
      },
      icon: {
        color: DARK_BLUE,
        "&:hover": {
          color: WHITE,
        },
      },
      select: {
        "&:focus": {
          backgroundColor: WHITE,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "rgba(0,0,0,.8)",
      },
      arrow: {
        color: "rgba(0,0,0,.8)",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: LIGHT_BLUE,
        },
        "&$selected:hover": {
          backgroundColor: LIGHT_BLUE,
        },
      },
      button: {
        "&:hover": {
          backgroundColor: LIGHT_GRAY,
        },
      },
    },
  },
});

export default theme;
