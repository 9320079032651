import React, { useState, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { humanizeValue, ATTRIBUTE_LABELS } from "../constants";
import UnderlinedIconButton from "./UnderlinedIconButton";
import KEKIcon from "./KEKIcon";
import { Button, Box } from "@material-ui/core";

const isEmptyValue = value => typeof value === "undefined";

const useAttributeSections = type => {
  switch (type) {
    case "print":
      return [
        [
          "pressType",
          "pressMagazineType",
          "pressKind",
          "pressPublishingIntervals",
        ],
        ["pressEditionsSold", "pressEditionsEpaper", "pressAsOfDate"],
      ];
    case "tv":
      return [
        [
          "rfRepresentative",
          "rfParentalAdvisor",
          "rfDirector",
          "rfCategory",
          "languages",
        ],
        ["platformOperators"],
        [
          "rfSupervisingAuthority",
          "rfBroadcastStatus",
          "rfStartDate",
          "rfLicenseFrom",
          "rfLicenseUntil",
          "rfLicensed",
        ],
        ["rfPublicPrivate", "rfFreePay", "rfStatewide"],
      ];
    case "online":
      return [
        ["onlineVisitsIVW", "onlineIVWPI", "onlineAsOfDateIVW"],
        ["onlineAGOF", "onlineAsOfDateAGOF"],
      ];
    case "radio":
      return [
        ["rfPublicPrivate", "rfFreePay", "rfStatewide"],
        [
          "rfSupervisingAuthority",
          "rfBroadcastStatus",
          "rfStartDate",
          "rfLicenseFrom",
          "rfLicenseUntil",
          "rfLicensed",
        ],
        ["rfRepresentative", "rfParentalAdvisor", "rfDirector", "languages"],
        ["platformOperators"],
      ];
  }
};

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridAutoRows: "auto",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  sectionCol: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  section: {
    background: theme.palette.background.blue,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  attribute: {},
  empty: {
    color: theme.palette.grey[500],
  },
  value: {
    whiteSpace: "pre-wrap",
    "& ul": {
      padding: 0,
      paddingLeft: theme.spacing(1),
    },
    "& ul li": {
      paddingLeft: theme.spacing(1),
    },
    "& ul li::marker": {
      content: '"• " !important',
    },
  },
}));

function AttributeDisplay(props) {
  const { label, value, isEmpty } = props;
  const classes = useStyles(props);
  if (isEmpty) return null;
  return (
    <div className={classes.attribute}>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
      <Typography
        component="div"
        variant="body1"
        className={cx(classes.value, {
          [classes.empty]: isEmpty,
        })}
      >
        {value}
      </Typography>
    </div>
  );
}

AttributeDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
};

function AttributeSection(props) {
  const { data } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.section}>
      {data.map(v => (
        <AttributeDisplay
          key={v.label}
          label={v.label}
          value={v.humanizedValue}
          isEmpty={v.isEmpty}
        />
      ))}
    </div>
  );
}

AttributeSection.propTypes = {
  data: PropTypes.array.isRequired,
};

const MediaProfileMeta = props => {
  const { data, open } = props;
  const classes = useStyles(props);
  const [isOpen, setIsOpen] = useState(open);
  const attributeSections = useAttributeSections(data.type);
  const resolvedSections = useMemo(
    () =>
      attributeSections
        .map(attributes =>
          attributes
            .map(a => ({
              label: ATTRIBUTE_LABELS[a],
              value: data[a],
              humanizedValue: humanizeValue(a, data[a]),
              isEmpty: isEmptyValue(data[a]),
            }))
            .filter(v => !!v.humanizedValue),
        )
        .filter(s => s.length !== 0),
    [attributeSections, data],
  );

  const sections = useMemo(
    () =>
      resolvedSections.reduce(
        (acc, section, i) => {
          acc[i % 2].push(section);
          return acc;
        },
        [[], []],
      ),
    [resolvedSections],
  );

  if (resolvedSections.length === 0)
    return (
      <Typography variant="body1" gutterBottom className={classes.indent}>
        Es liegen keine weiteren Angaben zu diesem Angebot vor.
      </Typography>
    );

  return (
    <React.Fragment>
      {!open && (
        <Box mb={2} mt={2}>
          <Button
            onClick={() => setIsOpen(!isOpen)}
            variant="contained"
            color="secondary"
            startIcon={<KEKIcon icon={!isOpen ? "Expand" : "Collapse"} />}
          >
            <span>
              {isOpen ? "Weniger Informationen" : "Mehr Informationen"}
            </span>
          </Button>
        </Box>
      )}
      {isOpen && (
        <div className={classes.sectionWrapper}>
          <div className={classes.sectionCol}>
            {sections[0].map(section => (
              <AttributeSection
                key={section.map(v => v.label).join("-")}
                data={section}
              />
            ))}
          </div>
          <div className={classes.sectionCol}>
            {sections[1].map(section => (
              <AttributeSection
                key={section.map(v => v.label).join("-")}
                data={section}
              />
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

MediaProfileMeta.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default MediaProfileMeta;
