import React, { useState } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FilterButton from "../components/FilterButton";
import ResultSection from "../components/ResultSection";
import Spinner from "../components/Spinner";
import FilterSection from "../components/FilterSection";
import FormatNumber from "../components/FormatNumber";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/FilledInput";
import CloseIcon from "../components/Icons/CloseIcon";
import { CATEGORIES } from "../constants";

import ExpandIcon from "../components/Icons/ExpandIcon";
import CollapseIcon from "../components/Icons/CollapseIcon";
const formatCount = items => {
  if (!items) return null;
  return (
    <React.Fragment>
      (<FormatNumber>{items.length}</FormatNumber>)
    </React.Fragment>
  );
};

const styles = theme => ({
  active: {
    color: theme.palette.primary.dark,
    opacity: 1,
  },
  inactive: {
    color: theme.palette.primary.dark,
    opacity: 0.3,
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    flexWrap: "wrap",
    "& > *": {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
      "&:first-child": {
        paddingLeft: 0,
      },
    },
  },
  resetButton: {
    color: theme.palette.text.primary,
    marginRight: 0,
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
    },
  },
  resultsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  search: {
    backgroundColor: theme.palette.gray.light,
  },
});

@withStyles(styles)
@inject("dataStore")
@observer
class Overview extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dataStore: PropTypes.object.isRequired,
  };

  get items() {
    const { dataStore } = this.props;
    return dataStore.filteredItems;
  }

  get numItems() {
    const { dataStore } = this.props;
    if (dataStore.selectedTypes.length === 0) return this.items.length;
    return this.items.filter(i => dataStore.selectedTypes.indexOf(i.type) > -1)
      .length;
  }

  get itemsByType() {
    const { dataStore } = this.props;
    return dataStore.filteredItemsByType;
  }

  componentDidMount() {
    const { dataStore } = this.props;
    dataStore.fetchOverview();
  }

  renderLoading = () => {
    return <Spinner />;
  };

  handleToggleTypeFilter = type => () => {
    const { dataStore } = this.props;
    dataStore.toggleSelectedType(type);
  };

  handleSearch = e => {
    const { dataStore } = this.props;
    dataStore.setSearchQuery(e.target.value);
  };

  handleSearchReset = e => {
    const { dataStore } = this.props;
    e.preventDefault();
    // clearing the values
    dataStore.setSearchQuery("");
  };

  handleShowAll = e => {
    const { dataStore } = this.props;
    if (dataStore.showAll) {
      dataStore.setShowAll(false);
    } else {
      dataStore.setShowAll(true);
    }
  };

  isTypeActive = type => {
    const { dataStore } = this.props;
    return dataStore.selectedTypes.indexOf(type) > -1;
  };

  get disableUnfoldAll() {
    return !CATEGORIES.every(([key]) => {
      const items = this.itemsByType[key];
      return items && items.length >= 25;
    });
  }

  render() {
    const { classes, dataStore } = this.props;
    if (!dataStore.isOverviewLoaded) return this.renderLoading();
    return (
      <div>
        <Typography variant="h1" gutterBottom>
          Mediendatenbank
        </Typography>
        <Typography variant="caption" gutterBottom>
          Durch die Eingabe in das Suchfeld werden die Suchergebnisse
          automatisch gefiltert.
        </Typography>
        <FilledInput
          className={classes.search}
          id="kek-search"
          placeholder="Suche ..."
          value={dataStore.searchQuery}
          onChange={this.handleSearch}
          variant="filled"
          margin="dense"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="reset-search-input"
                onClick={this.handleSearchReset}
                onMouseDown={this.handleSearchReset}
                disabled={dataStore.searchQuery.length === 0}
              >
                <CloseIcon
                  className={
                    dataStore.searchQuery.length > 0
                      ? classes.active
                      : classes.inactive
                  }
                />
              </IconButton>
            </InputAdornment>
          }
        />
        <div className={classes.filterContainer}>
          <Typography variant="caption">Profiltyp</Typography>
          {CATEGORIES.map(([key, label]) => (
            <FilterButton
              key={key}
              onClick={this.handleToggleTypeFilter(key)}
              isActive={this.isTypeActive(key)}
            >
              {label} {formatCount(this.itemsByType[key])}
            </FilterButton>
          ))}
          <Button
            className={classes.resetButton}
            size="medium"
            onClick={dataStore.handleResetAllFilters}
            disabled={!dataStore.filterActive}
          >
            Filter zurücksetzen
          </Button>
        </div>
        <FilterSection />
        <div className={classes.resultsContainer}>
          <Typography variant="caption">
            Ingesamt &apos;<FormatNumber>{this.numItems}</FormatNumber>&apos;
            Ergebnisse gefunden
          </Typography>
          <Button
            variant="text"
            disabled={this.disableUnfoldAll}
            onClick={this.handleShowAll}
            size="small"
            endIcon={dataStore.showAll ? <CollapseIcon /> : <ExpandIcon />}
          >
            Alle {dataStore.showAll ? "einklappen" : "ausklappen"}
          </Button>
        </div>
        {CATEGORIES.map(([key, label]) => (
          <ResultSection
            key={key}
            type={key}
            title={label}
            items={this.itemsByType[key]}
            showAll={this.isTypeActive(key) || dataStore.showAll}
            filtersActive={dataStore.selectedTypes.length > 0}
          />
        ))}
      </div>
    );
  }
}

export default Overview;
