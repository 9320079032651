import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/styles";
import ModCrumb from "../components/ModCrumb";
import Spinner from "../components/Spinner";
import Shareholder from "../components/Shareholder";
import QuickSearch from "../components/QuickSearch";

const styles = theme => ({
  root: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: "100px",
    },
  },
});

@withStyles(styles)
@inject("dataStore")
@observer
class ShareholderProfile extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dataStore: PropTypes.object.isRequired,
  };

  get squuid() {
    return this.props.params.squuid;
  }

  get profile() {
    return this.props.dataStore.profilesBySquuid[this.squuid];
  }

  get isProfileLoaded() {
    return this.profile !== undefined;
  }

  loadData = async () => {
    const { dataStore } = this.props;
    dataStore.setSearchQuery("");
    await dataStore.fetchMkmAsync();
    await dataStore.fetchShareholderProfile(this.squuid);
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(lastProps) {
    if (lastProps.params.squuid !== this.props.params.squuid) {
      this.loadData();
    }
  }
  renderLoading = () => {
    return <Spinner />;
  };

  render() {
    const { classes } = this.props;
    if (!this.isProfileLoaded) return this.renderLoading();
    return (
      <div className={classes.root}>
        <ModCrumb label={this.profile.name} />
        <QuickSearch />
        <Shareholder profile={this.profile} entrySquuid={this.squuid} />
      </div>
    );
  }
}

export default ShareholderProfile;
