import React from "react";
import { withStyles } from "@material-ui/styles";
import cx from "classnames";
import PropTypes from "prop-types";

const styles = theme => ({
  section: {
    position: "relative",
    padding: theme.spacing(2, 0, 0, 0),
    margin: theme.spacing(0, 0, 3, 0),
  },
  borderTop: {
    borderTop: `3px solid ${theme.palette.primary.dark}`,
  },
  graph: {
    backgroundColor: theme.palette.background.blue,
    paddingBottom: theme.spacing(8),
  },
  noTop: {
    padding: 0,
  },
  xlBottom: {
    marginBottom: theme.spacing(9),
  },
  mdBottom: {
    marginBottom: theme.spacing(6),
  },
});

const Section = ({
  classes,
  className,
  borderTop,
  graph,
  noTop,
  xlBottom,
  mdBottom,
  noBorder,
  ...props
}) => (
  <section
    className={cx(classes.section, className, {
      [classes.borderTop]: borderTop,
      [classes.graph]: graph,
      [classes.noTop]: noTop,
      [classes.xlBottom]: xlBottom,
      [classes.mdBottom]: mdBottom,
    })}
    {...props}
  />
);

Section.propTypes = {};

export default withStyles(styles)(Section);
