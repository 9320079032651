import React from "react";
import { MobXProviderContext } from "mobx-react";
import { groupBy, keyBy, uniqBy } from "lodash";

export function useStores() {
  return React.useContext(MobXProviderContext);
}

export function alphabetical(a, b) {
  const compA = a.toLowerCase();
  const compB = b.toLowerCase();
  if (compA < compB) return -1;
  if (compA > compB) return 1;
  return 0;
}

export function getOperations(tree, squuid) {
  const operations = tree.links
    .filter(l => l["holder"].squuid === squuid)
    .map(l => l["held"])
    .filter(n => !!n.type)
    .sort((a, b) => alphabetical(a.name, b.name));
  return uniqBy(operations, o => o.squuid);
}

export function getLinks(tree, squuid, accessor) {
  const links = tree.links
    .filter(l => l[accessor]?.squuid === squuid)
    .filter(l => !l["held"].type)
    .sort((a, b) => {
      if (a.capitalShares === b.capitalShares) {
        return a[accessor].name > b[accessor].name;
      }
      return b.capitalShares - a.capitalShares;
    });
  return uniqBy(links, l => `${l.held.name}-${l.holder.name}`);
}
