import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import cx from "classnames";
import { inject } from "mobx-react";
import {
  CREATE_LINK_TO_MDB,
  PROVIDER_LABELS_BY_MEDIA_TYPE,
} from "../constants";
import UnderlinedIconButton from "./UnderlinedIconButton";
const useStyles = makeStyles(theme => ({
  a: {
    textDecoration: "none",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    "&:hover": {
      borderBottom: `1px solid ${theme.palette.primary.dark}`,
      color: theme.palette.primary.dark,
    },
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    paddingRight: theme.spacing(5),
  },
  claim: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: "18px",
    verticalAlign: "bottom",
    [theme.breakpoints.down("sm")]: {
      width: "17px",
      verticalAlign: "sub",
    },
  },
  span: {
    whiteSpace: "nowrap",
  },
  type: {
    color: props => theme.palette.types[props.type],
  },
}));

const MarketByMediaType = props => {
  const { type } = props;
  switch (type) {
    case "ON":
      return "Onlinemarkt";
    case "TV":
      return "Fernsehmarkt";
    case "HF":
      return "Radiomarkt";
    case "TZ":
      return "Tageszeitungsmarkt";
    case "PZ":
      return "Zeitschriftenmarkt";
    default:
      return "Medienmarkt";
  }
};

const MediaMVMMeta = props => {
  const { item, dataStore } = props;
  const classes = useStyles({ ...props, ...item });
  const mvmData = dataStore.mkmByMediaSquuid[item.squuid];
  if (!mvmData) return null;
  const coporations = dataStore.mkmCoporationsForMediaSquuid(item.squuid);
  const name = item.name;
  const value = mvmData["MA (%)"].toLocaleString();
  const valueRounded = Math.round(value * 100) / 100;
  const coporation = mvmData["Konzern"];
  const mediaType = mvmData["Mediengattung"];
  const multipleCoporations = coporations.length > 1;
  const prefix = multipleCoporations ? "den Konzernen " : "dem Konzern ";
  const coporationLinks = coporations.map((c, index) => {
    const href = CREATE_LINK_TO_MDB(`/?companyFilter=${c}`);
    const link = (
      <UnderlinedIconButton
        LinkComponent="a"
        icon="ExtLink"
        target="_blank"
        isLink
        href={href}
      >
        {c}
      </UnderlinedIconButton>
    );

    const isLast = index === coporations.length - 1;
    const isPreLast = index === coporations.length - 2;
    const delimeter = isLast ? "" : isPreLast ? "und " : ", ";

    return (
      <React.Fragment key={href}>
        {link} {delimeter}
      </React.Fragment>
    );
  });
  return (
    <React.Fragment>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.claim}>
          Mediennutzungsbezogene Marktanteile von{" "}
          <span className={cx(classes.type)}>{name}</span> werden im Rahmen des
          Medienvielfaltsmonitors ausgewiesen.
        </Typography>
        <Typography variant="body1">
          Der Anteil von {name} am <MarketByMediaType type={mediaType} />{" "}
          beträgt <b>{valueRounded}%</b> (Grundlage:{" "}
          {PROVIDER_LABELS_BY_MEDIA_TYPE[mediaType]}). Entsprechend der
          Richtlinien des Medienvielfaltsmonitors wird
          <span className={cx(classes.type)}>
            <b> {name} </b>
          </span>
          {prefix}
          {coporationLinks}
          zugeordnet.
        </Typography>
      </div>
    </React.Fragment>
  );
};

MediaMVMMeta.propTypes = {
  item: PropTypes.object.isRequired,
};

export default inject("dataStore")(MediaMVMMeta);
