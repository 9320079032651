import { types, flow } from "mobx-state-tree";
import { observable } from "mobx";
import { groupBy, keyBy, uniqBy } from "lodash";
import {
  getAllShareholders,
  getAllMedia,
  getOneShareholder,
  getOneMedia,
  getMediaTree,
  fetchMkmData,
} from "../api";
import Graph from "./GraphData";
import { alphabetical } from "../components/utils";

const NO_NAME = "-";

const resolveTree = (squuid, nodes, links) => {
  const resolvedLinks = links.map(link => {
    const holder = nodes.find(n => n.id === link.holder);
    const held = nodes.find(n => n.id === link.held);
    return {
      ...link,
      holder,
      held,
    };
  });
  return {
    nodes,
    links: resolvedLinks,
  };
};

const simplifyString = string => string.toLowerCase().trim();

const makeProfile = ({
  name = NO_NAME,
  organizations = [],
  type = "shareholder",
  ...rest
}) => ({
  name,
  organizations,
  type,
  ...rest,
});

const DataStore = types
  .model({
    isOverviewLoaded: types.optional(types.boolean, false),
    mkmIsLoaded: types.optional(types.boolean, false),
    selectedTypes: types.optional(types.array(types.string), []),
    searchQuery: types.optional(types.string, ""),
    showAll: types.optional(types.boolean, false),
    languageFilterValue: types.optional(types.string, ""),
    rfCategoryValue: types.optional(types.string, ""),
    rfBroadcastStatusValue: types.optional(types.string, ""),
    rfPublicPrivateValue: types.optional(types.string, ""),
    rfStatewideValue: types.optional(types.string, ""),
    organizationValue: types.optional(types.string, ""),
    rfSupervisingAuthorityValue: types.optional(types.string, ""),
    mediaTypeFilter: types.optional(types.string, ""),
  })
  .volatile(() => ({
    data: observable.array([]),
    profiles: observable.array([]),
    mkmData: [],
  }))
  .actions(self => {
    const resetMediaTypeFilter = () => {
      self.mediaTypeFilter = "";
    };
    const setMediaTypeFilter = type => {
      self.mediaTypeFilter = type;
    };
    const fetchMkmAsync = flow(function* () {
      if (self.mkmIsLoaded) return self.mkmData;
      const mkmData = yield fetchMkmData();
      self.mkmData = mkmData;
      self.mkmIsLoaded = true;
      return self.mkmData;
    });
    const fetchOverview = flow(function* () { // eslint-disable-line
      if (self.isOverviewLoaded) return self.data;
      const [shareholders, media] = yield Promise.all([
        getAllShareholders(),
        getAllMedia(),
      ]);
      const filteredMedia = media.data.filter(
        r => !(r.organization && r.organization.name === "GGS"),
      );
      const filteresShareholders = shareholders.data.filter(
        r =>
          !(
            r.organizations &&
            r.organizations.length === 1 &&
            r.organizations.findIndex(o => o.name === "GGS") > -1
          ),
      );
      const response = [...filteresShareholders, ...filteredMedia];
      const data = response
        .filter(r => r.state === "active")
        .map(makeProfile)
        .map(p => {
          if (p.type === "online" || p.type === "print") {
            delete p.rfStatewide;
            delete p.rfPublicPrivate;
          }
          return p;
        })
        .sort((a, b) => {
          if (a.name === NO_NAME) return 1;
          if (b.name === NO_NAME) return -1;
          if (simplifyString(a.name) < simplifyString(b.name)) return -1;
          if (simplifyString(a.name) > simplifyString(b.name)) return 1;
          return 0;
        });
      self.data.replace(data);
      self.isOverviewLoaded = true;
      return data;
    });

    const addProfile = profile => {
      const index = self.profiles.findIndex(p => p.squuid === profile.squuid);
      if (index === -1) {
        self.profiles.push(profile);
      }
    };

    const fetchMediaProfile = flow(function* (squuid) {
      const response = yield getOneMedia(squuid);
      if (response.status === 200) {
        addProfile(response.data);
        if (response.data.operatedBy) {
          response.data.operatedBy.forEach(operator => {
            self.fetchShareholderProfile(operator.holder.squuid);
          });
        }
      }
      return response.data;
    });

    const fetchShareholderProfile = flow(function* (squuid) {
      const response = yield getOneShareholder(squuid);
      const tree = yield getMediaTree(squuid);
      if (response.status === 200) {
        const { nodes, links } = tree.data;
        response.data.tree = resolveTree(squuid, nodes, links);
        addProfile(response.data);
      }
      return response.data;
    });

    const loadShareholderTree = flow(function* (squuid) {
      const response = yield getMediaTree(squuid);
      if (response.status === 200) {
        const { nodes, links } = response.data;
        self.tree = resolveTree(nodes, links);
      }
    });

    const toggleSelectedType = type => {
      const index = self.selectedTypes.indexOf(type);
      if (index === -1) {
        self.selectedTypes.push(type);
      } else {
        self.selectedTypes.splice(index, 1);
      }
      if (self.selectedTypes.length > 0) {
        self.setShowAll(false);
      }
    };

    const setSearchQuery = query => {
      self.searchQuery = query;
    };

    const setShowAll = showAll => {
      self.showAll = showAll;
      if (self.showAll) {
        self.selectedTypes = [];
      }
    };

    const handleLanguageFilter = val => {
      self.languageFilterValue = val;
    };

    const handleRfCategoryFilter = val => {
      self.rfCategoryValue = val;
    };

    const handleRfBroadcastStatus = val => {
      if (val === self.rfBroadcastStatusValue) {
        self.rfBroadcastStatusValue = "";
      } else {
        self.rfBroadcastStatusValue = val;
      }
    };

    const handleRfPublicPrivate = val => {
      if (val === self.rfPublicPrivateValue) {
        self.rfPublicPrivateValue = "";
      } else {
        self.rfPublicPrivateValue = val;
      }
    };

    const handleRfStatewide = val => {
      if (val === self.rfStatewideValue) {
        self.rfStatewideValue = "";
      } else {
        self.rfStatewideValue = val;
      }
    };

    const handleOrganization = val => {
      self.organizationValue = val;
    };
    const handleRfSupervisingAuthority = val => {
      self.rfSupervisingAuthorityValue = val;
    };
    const handleResetAllFilters = () => {
      self.rfSupervisingAuthorityValue = "";
      self.organizationValue = "";
      self.selectedTypes = [];
      self.languageFilterValue = "";
      self.rfCategoryValue = "";
      self.rfBroadcastStatusValue = "";
      self.rfPublicPrivateValue = "";
      self.rfStatewideValue = "";
      self.rfSupervisingAuthorityValue = "";
    };
    const testGraph = squuid => {
      return new Promise(resolve => {
        self.fetchShareholderProfile(squuid).then(profileData => {
          resolve(self.profilesBySquuid[squuid]);
        });
      });
    };

    const testAllGraphs = async () => {
      await self.fetchOverview();
      const shareholderProfiles = self.data.filter(
        p => p.type === "shareholder",
      );
      for (let i = 3790; i < shareholderProfiles.length; i++) {
        const { squuid, name } = shareholderProfiles[i];
        const profileData = await self.testGraph(squuid);
        console.log(
          `test ${i} of ${shareholderProfiles.length}, ${squuid}, ${name}`,
        );
        try {
          const graph = Graph.create(
            profileData.squuid,
            profileData.tree,
            self,
          );
        } catch (e) {
          console.log("error with squuid", squuid);
          console.error(e);
        }
      }
    };

    return {
      resetMediaTypeFilter,
      setMediaTypeFilter,
      fetchMkmAsync,
      fetchOverview,
      toggleSelectedType,
      setSearchQuery,
      loadShareholderTree,
      fetchMediaProfile,
      fetchShareholderProfile,
      setShowAll,
      handleLanguageFilter,
      handleRfCategoryFilter,
      handleRfBroadcastStatus,
      handleRfPublicPrivate,
      handleOrganization,
      handleRfStatewide,
      handleRfSupervisingAuthority,
      handleResetAllFilters,
      testGraph,
      testAllGraphs,
    };
  })
  .views(self => ({
    get mkmValueForCoporation() {
      const VALUE_KEY = "MA * Faktor in %";
      return squuid =>
        self.mkmData
          .filter(i => i["ID Konzern"] === squuid)
          .reduce((acc, i) => acc + +i[VALUE_KEY], 0);
    },
    get mkmByCoporationSquuid() {
      return keyBy(self.mkmData, d => d["ID Konzern"]) || {};
    },
    get mkmByMediaSquuid() {
      return keyBy(self.mkmData, d => d["ID Angebot"]) || {};
    },
    get mkmCoporationsForMediaSquuid() {
      return squuid =>
        self.mkmData
          .filter(m => m["ID Angebot"] === squuid)
          .map(m => m["Konzern"]);
    },
    get profilesBySquuid() {
      return keyBy(self.profiles, d => d.squuid) || {};
    },
    get bySquuid() {
      return (self.isOverviewLoaded && keyBy(self.data, d => d.squuid)) || {};
    },
    get byType() {
      return (self.isOverviewLoaded && groupBy(self.data, d => d.type)) || {};
    },
    get filterActive() {
      if (self.languageFilterValue != "") {
        return true;
      }
      if (self.rfCategoryValue != "") {
        return true;
      }
      if (self.rfBroadcastStatusValue != "") {
        return true;
      }
      if (self.rfPublicPrivateValue != "") {
        return true;
      }
      if (self.rfStatewideValue != "") {
        return true;
      }
      if (self.rfSupervisingAuthorityValue != "") {
        return true;
      }
      if (self.selectedTypes.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    get filteredItems() {
      let data = [];
      if (self.isOverviewLoaded) {
        data = self.data;
        if (self.searchQuery.length > 2) {
          data = data.filter(i =>
            i.name.toLowerCase().includes(self.searchQuery.toLowerCase()),
          );
        }
        if (self.languageFilterValue.length > 0) {
          data = data.filter(
            i =>
              i.languages &&
              i.languages.findIndex(l => l.name === self.languageFilterValue) >
                -1,
          );
        }
        if (self.rfCategoryValue.length > 0) {
          data = data.filter(
            i => i.rfCategory && i.rfCategory.name === self.rfCategoryValue,
          );
        }
        if (self.rfBroadcastStatusValue.length > 0) {
          data = data.filter(
            i =>
              i.rfBroadcastStatus &&
              i.rfBroadcastStatus.name === self.rfBroadcastStatusValue,
          );
        }
        if (self.organizationValue.length > 0) {
          data = data.filter(
            i =>
              i.organization && i.organization.name === self.organizationValue,
          );
        }
        if (self.rfSupervisingAuthorityValue.length > 0) {
          data = data.filter(
            i =>
              i.rfSupervisingAuthority &&
              i.rfSupervisingAuthority.name ===
                self.rfSupervisingAuthorityValue,
          );
        }
        if (self.rfStatewideValue.length > 0) {
          const comp = self.rfStatewideValue === "true";
          data = data.filter(
            i => i.hasOwnProperty("rfStatewide") && i.rfStatewide === comp,
          );
        }
        if (self.rfPublicPrivateValue.length > 0) {
          const comp = self.rfPublicPrivateValue === "true";
          data = data.filter(
            i =>
              i.hasOwnProperty("rfPublicPrivate") && i.rfPublicPrivate === comp,
          );
        }
      }
      return data;
    },
    get filteredItemsByType() {
      return groupBy(self.filteredItems, d => d.type);
    },
    get languageFilterOptions() {
      const languages = self.data.reduce((acc, d) => {
        if (d.languages) {
          d.languages.forEach(language => {
            acc.push(language);
          });
        }
        return acc;
      }, []);
      const options = uniqBy(languages, o => o.squuid)
        .map(o => o.name)
        .sort(alphabetical);
      return options;
    },
    get rfCategoryOptions() {
      const options = uniqBy(
        self.data.reduce((acc, d) => {
          if (d.rfCategory) {
            acc = [...acc, d.rfCategory];
          }
          return acc;
        }, []),
        o => o.squuid,
      )
        .map(o => o.name)
        .sort(alphabetical);
      return options;
    },
    get rfBroadcastStatusOptions() {
      const options = uniqBy(
        self.data.reduce((acc, d) => {
          if (d.rfBroadcastStatus) {
            acc = [...acc, d.rfBroadcastStatus];
          }
          return acc;
        }, []),
        o => o.squuid,
      )
        .map(o => o.name)
        .sort(alphabetical);
      return options;
    },
    get rfPublicPrivateOptions() {
      return [true, false];
    },
    get rfStatewideOptions() {
      return [true, false];
    },
    get organizationOptions() {
      const options = uniqBy(
        self.data.reduce((acc, d) => {
          if (d.organization) {
            acc = [...acc, d.organization];
          }
          return acc;
        }, []),
        o => o.squuid,
      )
        .map(o => o.name)
        .filter(o => o !== "GGS" && o !== "KEK" && o !== "DL")
        .sort(alphabetical);
      return options;
    },
    get rfSupervisingAuthorityOptions() {
      const options = uniqBy(
        self.data.reduce((acc, d) => {
          if (d.rfSupervisingAuthority) {
            acc = [...acc, d.rfSupervisingAuthority];
          }
          return acc;
        }, []),
        o => o.squuid,
      )
        .map(o => o.name)
        .filter(o => o !== "GGS" && o !== "KEK" && o !== "DL")
        .sort(alphabetical);
      return options;
    },
  }));

const DataStoreInstance = DataStore.create({});

export default DataStoreInstance;

window.DataStore = DataStoreInstance;
