import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CollapseIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M19.295 12.993a.665.665 0 0 0 .488-.205.665.665 0 0 0 .204-.487.64.64 0 0 0-.204-.48.679.679 0 0 0-.488-.196L4.74 11.61a.741.741 0 0 0-.488.213.64.64 0 0 0-.204.48.66.66 0 0 0 .055.266.711.711 0 0 0 .37.37.66.66 0 0 0 .267.055h14.556z" />
    </SvgIcon>
  );
};

export default CollapseIcon;
