import React, { Component } from "react";
import PropTypes from "prop-types";

class Height extends Component {
  constructor(props) {
    super(props);
    const { scale } = props;

    this.state = {
      width: scale.range()[1],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);

    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState(state => {
      const { width } = window.getComputedStyle(this.element);

      return {
        ...state,
        width: parseInt(width, 10),
      };
    });
  };

  render() {
    const { children, scale, ...props } = this.props;
    const { width } = this.state;

    return (
      <div
        ref={ref => {
          this.element = ref;
        }}
        {...props}
      >
        {children(width, scale(width))}
      </div>
    );
  }
}

Height.propTypes = {
  scale: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
};

export default Height;
