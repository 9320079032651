import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExtLinkIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="m19.938 4.938-.172-.438-.438-.172h-4.781a.561.561 0 0 0-.422.18.6.6 0 0 0-.172.43c0 .166.057.307.172.421a.574.574 0 0 0 .422.172h3.36L12 11.421a.574.574 0 0 0-.172.423c0 .166.057.307.172.422a.574.574 0 0 0 .422.171.574.574 0 0 0 .422-.171l5.89-5.907v3.36a.6.6 0 0 0 .172.43c.115.12.255.18.422.18.167 0 .31-.06.43-.18s.18-.264.18-.43V4.938zm-.485 11.953c0 .468-.088.906-.265 1.312a3.516 3.516 0 0 1-.72 1.07 3.329 3.329 0 0 1-2.39.993H7.375c-.469 0-.906-.089-1.313-.266a3.446 3.446 0 0 1-1.796-1.797A3.248 3.248 0 0 1 4 16.891V8.188c0-.47.089-.907.266-1.313a3.446 3.446 0 0 1 1.796-1.797 3.248 3.248 0 0 1 1.313-.266H9.75c.156 0 .292.058.406.172.115.115.172.25.172.407a.6.6 0 0 1-.172.43A.544.544 0 0 1 9.75 6H7.375a2.09 2.09 0 0 0-1.531.64c-.427.428-.64.943-.64 1.548v8.703c0 .604.213 1.12.64 1.547.427.427.937.64 1.531.64h8.703c.604 0 1.12-.213 1.547-.64.427-.428.646-.943.656-1.547v-2.375c0-.157.058-.292.172-.407a.555.555 0 0 1 .406-.171c.167 0 .308.057.422.171.115.115.172.25.172.407v2.375z" />
    </SvgIcon>
  );
};

export default ExtLinkIcon;
