import React from "react";
import { withStyles } from "@material-ui/styles";
import { Link as RouterLink } from "react-router";
import cx from "classnames";

const styles = () => ({
  link: {
    color: "inherit",
    textDecoration: "none",
    "&:active": {
      textDecoration: "none",
    },
  },
});

const Link = ({ classes, className, href, to, ...props }) => (
  <RouterLink
    to={href || to}
    className={cx(classes.link, className)}
    {...props}
  />
);

export default withStyles(styles)(Link);
