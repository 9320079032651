import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Node from "./Node";

import { getOperations, getLinks } from "../utils";

const useStyles = makeStyles(theme => ({
  ul: {
    margin: 0,
    listStyle: "none",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(3),
    },
  },
}));

const CollapsibleTree = props => {
  const { tree, squuid, accessor, dataStore, parentSquuids, initialOpen } =
    props;
  const classes = useStyles(props);
  const children = getLinks(tree, squuid, accessor);
  const nodeAccessor = accessor === "held" ? "holder" : "held";
  return (
    <React.Fragment>
      <ul className={classes.ul}>
        {children.length > 0 &&
          children.map(link => {
            const node = link[nodeAccessor];
            if (node.type) return null;
            const operations = getOperations(tree, node.squuid);
            const nodeHasChildren =
              getLinks(tree, node.squuid, accessor).length > 0;
            const key = `${link.held.name}-${link.holder.name}`;
            const isRecursing = parentSquuids.includes(node.squuid);
            return (
              <Node
                key={key}
                link={link}
                name={node.name}
                squuid={node.squuid}
                hasChildren={nodeHasChildren}
                operations={operations}
                hideUnfold={isRecursing}
                initialOpen={!isRecursing && initialOpen}
              >
                <CollapsibleTree
                  initialOpen={!isRecursing}
                  tree={tree}
                  squuid={node.squuid}
                  accessor={accessor}
                  dataStore={dataStore}
                  parentSquuids={[...parentSquuids, node.squuid]}
                />
              </Node>
            );
          })}
      </ul>
      {children.length === 0 && !initialOpen && (
        <Typography variant="body1">Keine Einträge vorhanden</Typography>
      )}
    </React.Fragment>
  );
};

CollapsibleTree.propTypes = {
  tree: PropTypes.object.isRequired,
  squuid: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  dataStore: PropTypes.object.isRequired,
  parentSquuids: PropTypes.array,
  initialOpen: PropTypes.bool,
};

CollapsibleTree.defaultProps = {
  initialOpen: false,
  parentSquuids: [],
};

export default inject("dataStore")(observer(CollapsibleTree));
