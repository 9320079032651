import React from "react";
import { makeStyles } from "@material-ui/styles";
import cx from "classnames";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/FilledInput";
import CloseIcon from "./Icons/CloseIcon";
import SearchIcon from "./Icons/SearchIcon";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import UnderlinedIconButton from "./UnderlinedIconButton";
import { inject, observer } from "mobx-react";
import Link from "./Link";
import { formatItemLink } from "./ResultSection";
import Highlighter from "react-highlight-words";
import Divider from "@material-ui/core/Divider";
import { TYPE_LABELS } from "../constants";
import Spinner from "../components/Spinner";

const useStyles = makeStyles(theme => ({
  a: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    paddingRight: theme.spacing(3),
  },
  searchButton: {
    width: "51px",
    height: "51px",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  flexcontainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fullSearch: {
    marginTop: theme.spacing(1),
  },
  iconButton: {
    color: theme.palette.text.white,
  },
  root: {
    zIndex: 1,
    position: "absolute",
    top: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      top: `-${theme.spacing(7)}px`,
    },
  },
  search: {
    backgroundColor: theme.palette.gray.light,
    maxWidth: 450,
    flexGrow: 2,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "30vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "40vw",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  typography: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    fontWeight: 700,
  },
  link: {
    textDecoration: "none !important",
    display: "block",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
const useSquareStyles = makeStyles(theme => ({
  square: {
    marginRight: theme.spacing(1),
    alignSelf: "center",
    width: 8,
    height: 8,
    borderColor: props => theme.palette.types[props.type],
    backgroundColor: props => theme.palette.types[props.type],
  },
}));

const useCirclestyles = makeStyles(theme => ({
  circle: {
    marginRight: theme.spacing(1),
    borderRadius: "50%",
    alignSelf: "center",
    width: 8,
    height: 8,
    borderColor: props => theme.palette.types[props.type],
    backgroundColor: props => theme.palette.types[props.type],
  },
}));

const Square = props => {
  const classes = useSquareStyles(props);
  return <div className={classes.square} />;
};

const Circle = props => {
  const classes = useCirclestyles(props);
  return <div className={classes.circle} />;
};

const QuickSearch = props => {
  const { dataStore } = props;
  const inputRef = React.useRef();
  const classes = useStyles(props);
  const [showSuggestions, setShowSuggestions] = React.useState(false);

  const handleInputChange = e => {
    dataStore.setSearchQuery(e.target.value);
  };

  const handleSearchReset = () => {
    dataStore.setSearchQuery("");
  };

  const { filteredItems, searchQuery } = dataStore;
  const hasRelevantSearch = searchQuery.length > 3;
  const suggestionItems = filteredItems.slice(0, 10);

  const handleClickAway = () => {
    setShowSuggestions(false);
  };

  const handleFocusInput = () => {
    if (searchQuery.length > 3 && filteredItems.length > 0) {
      setShowSuggestions(true);
    }
  };

  React.useEffect(() => {
    if (searchQuery.length > 2 && filteredItems.length > 0) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [searchQuery, filteredItems]);

  React.useEffect(() => {
    if (!dataStore.isOverviewLoaded) {
      dataStore.fetchOverview();
    }
  }, []);

  const suggestions = React.useMemo(() => {
    if (!inputRef.current || !showSuggestions) return <></>;
    const { offsetWidth } = inputRef.current;
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <Paper
          elevation={10}
          style={{ maxWidth: offsetWidth, width: offsetWidth }}
        >
          <Box pt={1} pb={2}>
            {suggestionItems.map((i, index) => {
              const href = formatItemLink(i);
              return (
                <Link key={i.squuid} className={classes.link} href={href}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography variant="body1" className={classes.typography}>
                      <Highlighter
                        textToHighlight={i.name}
                        searchWords={[dataStore.searchQuery]}
                      />
                    </Typography>
                    <Box display="flex" flexDirection="row" pl={3}>
                      {i.type === "shareholder" ? (
                        <Circle type={i.type} />
                      ) : (
                        <Square type={i.type} />
                      )}
                      <Typography variant="capion" style={{ color: "#231F20" }}>
                        {" "}
                        {TYPE_LABELS[i.type]}{" "}
                      </Typography>
                    </Box>
                    <Divider />
                  </Box>
                </Link>
              );
            })}
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="primary"
              style={{ padding: "8px 24px 8px 24px" }}
            >
              {" "}
              Nicht dabei?{" "}
              <UnderlinedIconButton isLink href="/" icon="IntLink">
                Zur Übersicht
              </UnderlinedIconButton>
            </Typography>
          </Box>
        </Paper>
      </ClickAwayListener>
    );
  }, [hasRelevantSearch, showSuggestions, suggestionItems, inputRef]);

  return (
    <div className={classes.root}>
      <div className={classes.flexcontainer}>
        <div className={cx(classes.searchButton)}>
          <IconButton
            aria-label="delete"
            // onClick={ToDo: Like hitting enter}
          >
            <SearchIcon className={classes.iconButton} />
          </IconButton>
        </div>
        <FilledInput
          className={classes.search}
          id="quick-search"
          ref={inputRef}
          onClick={handleFocusInput}
          placeholder="Schnellsuche..."
          value={dataStore.searchQuery}
          onChange={handleInputChange}
          variant="filled"
          margin="dense"
          inputProps={{
            autoComplete: "off",
          }}
          disabled={!dataStore.isOverviewLoaded}
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="reset-search-input"
                onClick={handleSearchReset}
                disabled={dataStore.searchQuery.length === 0}
              >
                {!dataStore.isOverviewLoaded ? (
                  <Spinner small />
                ) : (
                  <CloseIcon />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        <Popper
          placement="bottom-end"
          id="quick-search-popper"
          open={showSuggestions}
          anchorEl={inputRef.current}
          style={{ zIndex: 1 }}
        >
          {suggestions}
        </Popper>
      </div>
      <div className={cx(classes.flexcontainer, classes.fullSearch)}>
        <Typography variant="body1">
          <UnderlinedIconButton isLink href="/" icon="IntLink">
            Zur Übersicht
          </UnderlinedIconButton>
        </Typography>
      </div>
    </div>
  );
};

export default inject("dataStore")(observer(QuickSearch));
