import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import { observer } from "mobx-react";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  node: {
    fill: theme.palette.grey[400],
    cursor: "pointer",
  },
  label: {
    display: "none",
  },
  active: {
    fill: theme.palette.primary.main,
  },
  focus: {
    fill: "transparent",
    outline: `1px solid ${theme.palette.primary.main}`,
    outlineOffset: 0.5,
  },
  visible: {
    display: "block",
  },
  textOutline: {
    stroke: theme.palette.text.white,
    strokeWidth: 4,
  },
});

const STROKE_RECT_SIZE = 5;
const STROKE_WIDTH = 2;

@withStyles(styles, { withTheme: true })
@observer
class OverlayLabel extends Component {
  render() {
    const { viewState, node, scale, classes, theme, highlightContainerRef } =
      this.props;
    const { active, belongsTo, hideLabel, idle } = viewState;
    const { x, y, type, name, radius, size } = node;

    let slicedName = name;

    if (!active && name?.length > 20) {
      slicedName = `${name.slice(0, 20)}…`;
    }

    let transform;
    let textTransform;
    let offset = 6 + size / 2;

    if (x && y) {
      transform = `translate(${x}, ${y})`;
    }

    if (scale) {
      textTransform = `scale(${scale})`;
      offset *= 1 / scale;
    }

    if (transform && active) {
      transform = `translate(${x}, ${y}) scale(1.2)`;
    }
    const isShareholder = type === "shareholder";
    const colors = belongsTo ? theme.palette.types : theme.palette.typesLight;

    const component = (
      <g
        className={cx(classes.node, {
          [classes.active]: active,
        })}
        ref={ref => (this.element = ref)}
        // In case the graph stopped ticking and we have coordinates
        transform={transform}
      >
        {isShareholder ? (
          <circle r={radius} stroke="white" strokeWidth={2} />
        ) : (
          <React.Fragment>
            {belongsTo && !isShareholder && (
              <rect
                width={size + STROKE_RECT_SIZE}
                height={size + STROKE_RECT_SIZE}
                fill="#ffffff"
                stroke={
                  idle
                    ? colors[type]
                    : active
                    ? theme.palette.primary.main
                    : theme.palette.grey[400]
                }
                strokeWidth={STROKE_WIDTH}
                transform={`translate(-${(size + STROKE_RECT_SIZE) / 2}, -${
                  (size + STROKE_RECT_SIZE) / 2
                })`}
              />
            )}
            <rect
              width={!belongsTo ? size + 2 : size}
              height={!belongsTo ? size + 2 : size}
              fill={idle || active ? colors[type] : theme.palette.grey[400]}
              transform={`translate(-${size / 2}, -${size / 2})`}
            />
          </React.Fragment>
        )}
        <g
          transform={textTransform}
          className={cx(classes.label, {
            [classes.visible]: active && !hideLabel,
          })}
        >
          <text
            x={offset}
            alignmentBaseline="middle"
            className={cx(classes.textOutline)}
          >
            {slicedName}
          </text>
          <text x={offset} alignmentBaseline="middle">
            {slicedName}
          </text>
        </g>
      </g>
    );

    const focus = active && !hideLabel;
    if (focus && highlightContainerRef) {
      return createPortal(component, highlightContainerRef);
    }

    return component;
  }
}
export default OverlayLabel;
