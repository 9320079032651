import React from "react";
import { makeStyles } from "@material-ui/styles";
import cx from "classnames";
const useStyles = makeStyles(() => ({
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  spinner: {
    background: "none",
    position: "relative",
    width: "100px",
    height: "100px",
    margin: "4rem auto",
    "& > div": {
      position: "absolute",
      display: "block",
      width: "60px",
      height: "60px",
      top: "20px",
      left: "20px",
      borderRadius: "30px",
      boxShadow: "0 2px 0 0 black",
      transformOrigin: "30px 31px",
      animation: "$spin .75s linear infinite",
    },
  },
  small: {
    width: "50px",
    height: "50px",
    margin: "4rem auto",
    "& > div": {
      width: "30px",
      height: "30px",
      top: "10px",
      left: "10px",
      borderRadius: "15px",
      transformOrigin: "15px 16px",
    },
  },
}));

const Spinner = props => {
  const { small } = props;
  const classes = useStyles(props);
  return (
    <div className={cx(classes.spinner, { [classes.small]: small })}>
      <div />
    </div>
  );
};

export default Spinner;
