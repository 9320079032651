import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SearchIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M9.905 6.3a3.98 3.98 0 0 1 1.594.322c.497.215.932.508 1.303.88.372.371.665.806.88 1.303a3.98 3.98 0 0 1 .322 1.594 3.98 3.98 0 0 1-.322 1.594 4.149 4.149 0 0 1-.88 1.303 4.149 4.149 0 0 1-1.303.88 3.98 3.98 0 0 1-1.594.322c-.565 0-1.1-.107-1.602-.322A4.033 4.033 0 0 1 7 13.296a4.217 4.217 0 0 1-.872-1.303 3.98 3.98 0 0 1-.322-1.594c0-.565.107-1.097.322-1.594.215-.497.505-.932.872-1.303.366-.372.8-.665 1.303-.88A4.035 4.035 0 0 1 9.905 6.3zm4.633 7.68a6.327 6.327 0 0 0 .918-1.657 5.448 5.448 0 0 0 .338-1.924c0-.817-.154-1.584-.463-2.3a5.958 5.958 0 0 0-1.264-1.877 5.958 5.958 0 0 0-1.877-1.265 5.715 5.715 0 0 0-2.285-.463c-.817 0-1.584.155-2.3.463a5.958 5.958 0 0 0-1.878 1.265 5.958 5.958 0 0 0-1.264 1.876A5.754 5.754 0 0 0 4 10.4c0 .817.154 1.58.463 2.293.31.712.73 1.335 1.264 1.869s1.16.955 1.877 1.264a5.754 5.754 0 0 0 2.3.463c.619 0 1.213-.094 1.783-.283a6.094 6.094 0 0 0 1.579-.785l4.947 4.947c.083.094.18.162.29.204a.941.941 0 0 0 .675 0 .873.873 0 0 0 .307-.204.896.896 0 0 0 .25-.636.896.896 0 0 0-.25-.636l-4.947-4.915z" />
    </SvgIcon>
  );
};

export default SearchIcon;
