import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import FilterButton from "../components/FilterButton";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandIcon from "../components/Icons/ExpandIcon";
import CollapseIcon from "../components/Icons/CollapseIcon";
import { useStores } from "./utils";
import { LMA_LABELS } from "../constants";

const createStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(6),
  },
  expandContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 0),
    cursor: "pointer",
    backgroundColor: props =>
      props.isOpen ? theme.palette.primary.light : "transparent",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: theme.palette.primary.light,
    },
  },
  title: {
    //  marginBottom: theme.spacing(1),
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1, 0, 2, 0),
  },
  buttonGroup: {
    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
  buttonText: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  isActive: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FilterSelect = observer(props => {
  // checks for window size of breakpoint sm
  const windowThreshold = useMediaQuery("(min-width:600px)");
  const { name, plural, options, value, onChange, map, style } = props;
  const classes = createStyles(props);
  return (
    <div className={classes.filterContainer}>
      <Typography variant="caption"> {name} </Typography>
      {style === "isToggle" && windowThreshold === true ? (
        <div className={classes.buttonGroup}>
          {options.map(o => (
            <FilterButton
              key={o}
              onClick={() => onChange(`${o}`)}
              isActive={value === `${o}`}
            >
              {map ? (map[o] ? map[o] : o) : o}
            </FilterButton>
          ))}
        </div>
      ) : (
        <FormControl variant="filled">
          <Select
            displayEmpty
            value={value}
            onChange={e => onChange(e.target.value)}
            inputProps={{
              name: "option",
              id: "optinon-native-helper",
            }}
          >
            <MenuItem value=""> Alle {plural}</MenuItem>
            {options.map(o => (
              <MenuItem key={o} value={o}>
                {map ? (map[o] ? map[o] : o) : o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
});

FilterSelect.defaultProps = {
  options: [],
  value: "",
};

const FilterSection = observer(props => {
  const { dataStore } = useStores();
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = createStyles({ ...props, isOpen });
  const handleToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const ActionIcon = isOpen ? CollapseIcon : ExpandIcon;

  return (
    <section className={classes.section}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div onClick={handleToggleIsOpen} className={classes.expandContainer}>
            <ActionIcon className={classes.actionIcon} />
            <Typography className={classes.title} variant="h4">
              Weitere Filteroptionen
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Collapse in={isOpen}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} md={7} lg={6}>
            <FilterSelect
              style="isToggle"
              name="Organisationstyp"
              plural="Organisationstypen"
              attr="rfPublicPrivate"
              options={dataStore.rfPublicPrivateOptions}
              value={dataStore.rfPublicPrivateValue}
              onChange={dataStore.handleRfPublicPrivate}
              map={{
                true: "Öffentlich-rechtlich",
                false: "Privat",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={5} lg={4}>
            <FilterSelect
              name="Programmkategorie"
              plural="Programmkategorien"
              attr="rfCategory"
              options={dataStore.rfCategoryOptions}
              value={dataStore.rfCategoryValue}
              onChange={dataStore.handleRfCategoryFilter}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={7} lg={6}>
            <FilterSelect
              style="isToggle"
              name="Verbreitung"
              plural="Verbreitung"
              attr="rfStatewide"
              options={dataStore.rfStatewideOptions}
              value={dataStore.rfStatewideValue}
              onChange={dataStore.handleRfStatewide}
              map={{
                true: "Bundesweit",
                false: "Regional",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={5} lg={4}>
            <FilterSelect
              name="Sprache"
              plural="Sprachen"
              attr="languages"
              options={dataStore.languageFilterOptions}
              value={dataStore.languageFilterValue}
              onChange={dataStore.handleLanguageFilter}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={7} lg={6}>
            <FilterSelect
              style="isToggle"
              name="Sendestatus"
              plural="Sendestati"
              attr="rfBroadcastStatus"
              options={dataStore.rfBroadcastStatusOptions}
              value={dataStore.rfBroadcastStatusValue}
              onChange={dataStore.handleRfBroadcastStatus}
              map={{
                "Sendebetrieb eingestellt": "Eingestellt",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={5} lg={4}>
            <FilterSelect
              name="Zuständige Landesmedienanstalt"
              plural="Landesmedienanstalten"
              attr="rfSupervisingAuthority"
              options={dataStore.rfSupervisingAuthorityOptions}
              value={dataStore.rfSupervisingAuthorityValue}
              onChange={dataStore.handleRfSupervisingAuthority}
              map={LMA_LABELS}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Divider />
    </section>
  );
});

FilterSection.propTypes = {};

FilterSection.defaultProps = {};

export default FilterSection;
