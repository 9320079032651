import axios from "axios";
import { API_BASE_PATH, API_BASE_URL } from "./constants";
import { tsvParse } from "d3-dsv";
// On the server we have this baseUrl already set (see server.js)
// But on the client its empty and we want to acess the api at `localhost/api`
// Solution: We just use `/api` as baseUrl if nothing was set.
const api = axios.create({
  baseURL: `${API_BASE_URL}${API_BASE_PATH}`,
});

const fileApi = axios.create({
  baseURL: `${API_BASE_URL}/static`,
});

/**
 * Helper function to create standardized error responses with fake 900 and 910 status. Also calls `dataStore.finishRequest()`
 * @param {any} error
 * @returns {object} result
 */
const createErrorResponse = error => {
  let result = null;
  if (error.response) result = error.response;
  else if (error.request) {
    result = error.request;
    result.status = 900;
  } else {
    result = error;
    result.status = 910;
  }
  return result;
};

/**
 * This higher order function takes in an array of handlers for statuscodes ala `[[statusCode, (response) => {...}], ...]`.
 * It then creates a function which can be used to process an api response. It calls the provided callback with the `response` as value, if their `response.status` matches `statusCode` and then returns the result of the callback.
 * @param {array} statusHandlers
 * @returns {[any|false]} result of the matching handler (or `false` no handler matched)
 */
export const handleResponse = statusHandlers => response => {
  const handler = statusHandlers.find(f => f[0] === response.status);
  if (!handler) {
    const defaultHandler = statusHandlers.find(f => f[0] === "default");
    if (defaultHandler) {
      return defaultHandler[1](response);
    }
  } else {
    return handler[1](response);
  }
  return false;
};

export async function fetchMkmData() {
  try {
    const response = await fileApi.get("/MKM_LandingPage_Viz_Data.tsv");
    const parsed = await tsvParse(response.data);
    return parsed;
  } catch (error) {
    createErrorResponse(error);
  }
}

export async function getAllMedia() {
  try {
    const response = await api.get("/media");
    return { ...response };
  } catch (error) {
    return createErrorResponse(error);
  }
}

export async function getOneMedia(id) {
  try {
    const response = await api.get(`/media/${id}`);
    return { ...response };
  } catch (error) {
    return createErrorResponse(error);
  }
}
export async function getAllShareholders() {
  try {
    const response = await api.get("/shareholders");
    return { ...response };
  } catch (error) {
    return createErrorResponse(error);
  }
}

export async function getOneShareholder(id) {
  try {
    const response = await api.get(`/shareholders/${id}`);
    return { ...response };
  } catch (error) {
    return createErrorResponse(error);
  }
}

export async function getMediaTree(uuid) {
  try {
    const response = await api.get(`media/${uuid}/static-tree`);
    return response;
  } catch (error) {
    return createErrorResponse(error);
  }
}

export default api;
