export const NODE_CIRCLE_RADIUS = 5;

export const NODE_RECT_SIZE = 10;

export const NODE_COLLIDE =
  Math.max(NODE_RECT_SIZE / 2, NODE_CIRCLE_RADIUS) + 10;

export const COLORS = {
  online: "#3A97AB",
  print: "#67AFFF",
  tv: "#A055AF",
  radio: "#03CBAD",
  shareholder: "#B6B7BB",
};

export const MEDIA_TYPES = ["tv", "rundfunk", "presse", "online"];

export const MEDIA_TYPE_LABELS = ["Fernsehen", "Radio", "Presse", "Online"];
