import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ModCrumb from "../components/ModCrumb";
import MediaProfileMeta from "../components/MediaProfileMeta";
import Spinner from "../components/Spinner";
import Section from "../components/Section";
import Shareholder from "../components/Shareholder";
import MediaMVMMeta from "../components/MediaMVMMeta";
import Type from "../components/Type";
import Grid from "@material-ui/core/Grid";
import QuickSearch from "../components/QuickSearch";
import cx from "classnames";

const styles = theme => ({
  blue: {
    backgroundColor: theme.palette.background.blue,
  },
  indent: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  paddingBottom: {
    paddingBottom: theme.spacing(10),
  },
  root: {
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(7),
    },
  },
});

@withStyles(styles)
@inject("dataStore")
@observer
class MediaProfile extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dataStore: PropTypes.object.isRequired,
  };

  get squuid() {
    return this.props.params.squuid;
  }

  get profile() {
    return this.props.dataStore.profilesBySquuid[this.squuid];
  }

  get isProfileLoaded() {
    return this.profile !== undefined;
  }

  get mvmData() {
    return this.props.dataStore.mkmByMediaSquuid[this.squuid];
  }

  loadData = async () => {
    const { dataStore } = this.props;
    dataStore.setSearchQuery("");
    await dataStore.fetchMkmAsync();
    await dataStore.fetchMediaProfile(this.squuid);
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(lastProps) {
    if (lastProps.params.squuid !== this.props.params.squuid) {
      this.loadData();
    }
  }

  renderLoading = () => {
    return <Spinner />;
  };

  get isEmptyProfile() {
    if (!this.mvmData) {
      if (!this.profile.description) {
        return true;
      }
    }
    return false;
  }

  isMetaOpen() {
    if (!this.mvmData) {
      if (!this.profile.description) {
        return true;
      }
    } else {
      return false;
    }
  }

  render() {
    const { classes, dataStore } = this.props;
    if (!this.isProfileLoaded) return this.renderLoading();
    const isMetaOpen = this.isMetaOpen();
    return (
      <div className={classes.root}>
        <ModCrumb label={this.profile.name} />
        <QuickSearch />
        <Grid container direction="row">
          <Grid item xs={12}>
            <Type type={this.profile.type} />
            <Typography variant="h2" className={classes.indent}>
              {this.profile.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={7} id="description">
            {this.profile.description && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.indent}
              >
                {this.profile.description}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={9} md={7} id="mvm">
            {this.mvmData && (
              <Section className={classes.indent}>
                <MediaMVMMeta
                  item={this.profile}
                  type={this.profile.type}
                  name={this.profile.name}
                />
              </Section>
            )}
          </Grid>
          <Grid item xs={12}>
            <Section className={classes.indent} noBorder xlBottom noTop>
              <MediaProfileMeta data={this.profile} open={isMetaOpen} />
            </Section>
          </Grid>
        </Grid>
        {!this.profile.operatedBy && (
          <Grid container direction="row" className={classes.blue}>
            <Grid item xs={12}>
              <Type type="shareholder" isAppendix={this.profile.type} />
              <Typography
                variant="body1"
                color="textPrimary"
                className={cx(classes.indent, classes.paddingBottom)}
              >
                Für das Angebot liegt keine Unternehmensstruktur vor.
              </Typography>
            </Grid>
          </Grid>
        )}
        {this.profile.operatedBy &&
          this.profile.operatedBy.map(o => {
            const squuid = o.holder.squuid;
            const shareholderProfile = dataStore.profilesBySquuid[squuid];
            return shareholderProfile ? (
              <React.Fragment key={shareholderProfile.squuid}>
                <Shareholder
                  profile={shareholderProfile}
                  isAppendix={this.profile.type}
                  entrySquuid={this.squuid}
                />
              </React.Fragment>
            ) : (
              <Spinner key={squuid} small />
            );
          })}
      </div>
    );
  }
}

export default MediaProfile;
