import React, { Component } from "react";
import PropTypes from "prop-types";
import { scaleLinear } from "d3-scale";

import Height from "./Height";
import GraphCanvas from "./Graph";

class Graph extends Component {
  get heightScale() {
    return scaleLinear().domain([400, 600]).rangeRound([200, 300]);
  }

  render() {
    const { graph, className, allOperates } = this.props;
    return (
      <Height scale={this.heightScale} className={className}>
        {(width, height) => {
          return (
            <GraphCanvas
              width={width}
              height={height}
              graph={graph}
              allOperates={allOperates}
            />
          );
        }}
      </Height>
    );
  }
}

Graph.propTypes = {
  graph: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Graph.defaultProps = {
  className: null,
};

export default Graph;
