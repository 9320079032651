import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExpandIcon = props => {
  return (
    <SvgIcon {...props}>
      <path d="M12.693 13.037h6.602a.665.665 0 0 0 .488-.205.665.665 0 0 0 .204-.487.64.64 0 0 0-.204-.48.679.679 0 0 0-.488-.196h-6.602l.016-6.602a.741.741 0 0 0-.213-.488.64.64 0 0 0-.48-.204.665.665 0 0 0-.486.204.665.665 0 0 0-.205.488v6.602l-6.586-.016a.741.741 0 0 0-.488.213.64.64 0 0 0-.204.479.66.66 0 0 0 .055.267.711.711 0 0 0 .37.37.66.66 0 0 0 .267.055h6.586v6.602c0 .094.019.18.055.26a.742.742 0 0 0 .37.361.66.66 0 0 0 .267.055.64.64 0 0 0 .48-.204.741.741 0 0 0 .212-.488l-.016-6.586z" />
    </SvgIcon>
  );
};

export default ExpandIcon;
