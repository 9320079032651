import React from "react";
import PropTypes from "prop-types";

const FormatNumber = props => {
  return <React.Fragment>{props.children.toLocaleString("de")}</React.Fragment>;
};

FormatNumber.propTypes = {
  children: PropTypes.number.isRequired,
};

export default FormatNumber;
