import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import Link from "./Link";
import FormatNumber from "./FormatNumber";

export const formatItemLink = item =>
  `/profile/${item.type === "shareholder" ? "shareholder" : "media"}/${
    item.squuid
  }`;

const styles = theme => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  list: {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    margin: 0,
    padding: 0,
  },
  btn: {
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    color: "black",
    border: "1px solid transparent",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  item: {
    padding: theme.spacing(0.5, 1),
    color: "white",
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    border: "1px solid transparent",
    borderColor: props => theme.palette.types[props.type],
    backgroundColor: props => theme.palette.types[props.type],
    cursor: "pointer",
    transition: "background-color ease-in-out 150ms, color ease-in-out 150ms",
    "&:hover": {
      backgroundColor: "transparent",
      color: props => theme.palette.types[props.type],
    },
  },
});

const ResultSection = ({ classes, type, filtersActive, ...props }) => {
  if (filtersActive && !props.showAll) return null;
  const [isOpen, setIsOpen] = useState(false);
  const showAll = isOpen || props.showAll;
  const items = showAll ? props.items : props.items.slice(0, 25);
  if (items.length === 0) return null;
  return (
    <section className={classes.section}>
      <Typography variant="h2">
        <b>{props.title}</b> (<FormatNumber>{props.items.length}</FormatNumber>)
      </Typography>
      <ul className={classes.list}>
        {items.map(item => (
          <li className={classes.item} key={item.squuid}>
            <Link href={formatItemLink(item)}>
              <Typography variant="body1" color="inherit">
                {item.name}
              </Typography>
            </Link>
          </li>
        ))}
        {props.items.length > 25 && !props.showAll && (
          <li className={cx(classes.btn)} onClick={() => setIsOpen(!isOpen)}>
            <Typography variant="body1" color="inherit">
              {isOpen ? (
                "weniger"
              ) : (
                <React.Fragment>
                  mehr (<FormatNumber>{props.items.length - 25}</FormatNumber>)
                </React.Fragment>
              )}
            </Typography>
          </li>
        )}
      </ul>
    </section>
  );
};

ResultSection.propTypes = {
  filtersActive: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array,
  showAll: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

ResultSection.defaultProps = {
  items: [],
  showAll: false,
  filtersActive: false,
};

export default withStyles(styles)(ResultSection);
