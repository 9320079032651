import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  hide: {
    display: "none",
  },
  meta: {
    paddingRight: theme.spacing(5),
  },
  toggle: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    margin: theme.spacing(2, 0, 2, 0),
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const ShareholderProfileMeta = props => {
  const { data } = props;
  const classes = useStyles(props);
  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.meta}>
          <Typography variant="body1">
            {data.street} {data.streetNumber}
          </Typography>
          <Typography variant="body1">
            {data.zipcode} {data.city}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

ShareholderProfileMeta.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ShareholderProfileMeta;
