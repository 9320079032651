import { uniqBy } from "lodash";
import { observable, computed, action, toJS } from "mobx";
import { scaleLinear } from "d3-scale";

const NODE_CIRCLE_RADIUS = 5;
const NODE_RECT_SIZE = 6;
const MAX_NODE_SCALE = 3;
const DEFAULT_ZOOM = { x: 0, y: 0, k: 1, transform: "" };

class Graph {
  @observable activatedNode = null;
  @observable hoveredNode = null;
  @observable zoom = { ...DEFAULT_ZOOM };

  static create(profileSquuid, tree, dataStore, entrySquuid) {
    const { nodes: rawNode, links: rawLinks } = tree;

    const nodes = rawNode
      .map(n => ({
        ...n,
        type: n.type || "shareholder",
      }))
      .filter(n => !!n.type);

    const links = rawLinks.map(l => {
      const target = nodes.find(n => n.id === l.held?.id);
      const source = nodes.find(n => n.id === l.holder?.id);
      return {
        target,
        source,
      };
    });

    const allLinks = [...links].filter(l => !!l.source && !!l.target);
    const allNodes = [...nodes];

    let minNodeLinks = Infinity;
    let maxNodeLinks = -Infinity;

    allNodes.forEach(node => {
      node.links = uniqBy(
        allLinks.filter(
          link => link.target.id === node.id || link.source.id === node.id,
        ),
        l => `${l.target.id}<->${l.source.id}`,
      );
      minNodeLinks = Math.min(minNodeLinks, node.links.length);
      maxNodeLinks = Math.max(maxNodeLinks, node.links.length);
    });

    const nodeScale = scaleLinear()
      .domain([minNodeLinks, maxNodeLinks])
      .range([1, MAX_NODE_SCALE]);

    allNodes.forEach(node => {
      const scale = nodeScale(node.links.length);

      node.radius = NODE_CIRCLE_RADIUS * scale;
      node.size = NODE_RECT_SIZE * scale;
    });

    const entrySquuidNode = allNodes.find(n => n.squuid === entrySquuid);

    const graph = new Graph({
      nodes: uniqBy(allNodes, n => n.id),
      links: allLinks,
      profileSquuid,
      dataStore,
      entrySquuid: entrySquuidNode ? entrySquuidNode.id : null,
      entrySquuidNode: entrySquuidNode,
    });
    return graph;
  }

  constructor(data) {
    Object.assign(this, data);
  }

  @action
  reset() {
    this.activatedNode = null;
    this.hoveredNode = null;
    this.zoom = { ...DEFAULT_ZOOM };
  }

  @action
  changeZoom(zoom) {
    this.zoom = zoom;
  }

  @computed get defaultActiveNode() {
    return this.nodes.find(node => node.squuid === this.profileSquuid);
  }

  @computed
  get visibleNodes() {
    return [this.defaultActiveNode.id, this.activeNode];
  }

  @computed
  get activeMediaItems() {
    if (this.nodesOnPathOfHoveredNode.length === 0) return [];
    return this.links
      .filter(l => {
        if (l.target.type === "shareholder") return false;
        return this.nodesOnPathOfHoveredNode.includes(l.source);
      })
      .map(l => l.target);
  }

  @computed
  get activeLinks() {
    if (!this.nodeForPathHighlighting) return [];
    return this.links.filter(
      l =>
        this.nodesOnPathOfHoveredNode.includes(l.target) &&
        this.nodesOnPathOfHoveredNode.includes(l.source),
    );
  }

  @computed
  get nodeForPathHighlighting() {
    return (
      this.hoveredNode ||
      (this.entrySquuidNode?.type !== "shareholder" && this.entrySquuid)
    );
  }

  @computed
  get nodesOnPathOfHoveredNode() {
    const startNode = this.nodeForPathHighlighting;
    if (!startNode && this.entrySquuidNode?.type === "shareholder")
      return [this.entrySquuidNode];
    if (!startNode) return [];

    const getNeighbours = (nodeId, direction) => {
      const otherSide = direction == "source" ? "target" : "source";
      return this.links
        .filter(l => l[direction].id == nodeId)
        .map(l => l[otherSide].id);
    };

    const bfs = (from, to, direction, initialValue) => {
      let firstChildren = getNeighbours(from, direction);
      let queue = [{ node: from, pathTo: [], children: firstChildren }];
      let explored = [from];

      while (queue.length > 0) {
        let curr = queue.shift();
        let nextPath = [...initialValue, ...curr.pathTo, curr.node];
        if (curr.node == to) {
          return nextPath;
        }
        for (let i = 0; i < curr.children.length; i++) {
          if (explored.indexOf(curr.children[i]) == -1) {
            explored.push(curr.children[i]);
            queue.push({
              node: curr.children[i],
              pathTo: nextPath,
              children: getNeighbours(curr.children[i], direction),
            });
          }
        }
      }

      return [];
    };

    const { type, links, id } = this.nodes.find(n => n.id === startNode);
    const mediaItemHovered = type !== "shareholder";

    const firstNode = mediaItemHovered ? links[0].source.id : startNode;
    const initialValue = mediaItemHovered ? [id] : [];
    const outwardNodes = bfs(
      firstNode,
      this.defaultActiveNode.id,
      "target",
      initialValue,
    );
    if (outwardNodes.length > 0) {
      return outwardNodes.map(i => this.nodes.find(n => n.id == i));
    }
    const inwardNodes = bfs(
      firstNode,
      this.defaultActiveNode.id,
      "source",
      initialValue,
    );
    return inwardNodes.map(i => this.nodes.find(n => n.id == i));
  }
  @computed
  get activeNode() {
    return (
      this.hoveredNode ||
      this.activatedNode ||
      (this.defaultActiveNode && this.defaultActiveNode.id) ||
      null
    );
  }

  @action
  activateNode(node) {
    if (node) {
      this.activatedNode = node.id;
    } else {
      this.activatedNode = null;
    }
  }

  @action
  hoverNode(node) {
    if (node) {
      this.hoveredNode = node.id;
    } else {
      this.hoveredNode = null;
    }
  }
}

export default Graph;
